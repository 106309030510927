import { appConfigKey } from '../../../../../../config/Config';
import './styles.scss';
import { Dialog } from "@mui/material";
import DOMPurify from 'dompurify';

const ValidarRequestDialog = (props: any) => {
    // Sanitizamos el texto para evitar XSS
    const sanitizedText = props?.text ? DOMPurify.sanitize(props.text) : '';
    const sanitizedDetail = props?.type === appConfigKey.keyTipoBusquedaEvalCrediticiaExcepcion
        ? appConfigKey.keyTextDetailEvalCrediticiaExcepcion.replace('[DATA]', sanitizedText.toLowerCase())
        : appConfigKey.keyTextDetailDefaultProceso.replace('[DATA]', sanitizedText.toLowerCase());

    return (
        <Dialog open={props.open} maxWidth={'sm'}>
            <div className='validar-request-dialong-content'>
                <div className='validar-request-dialog-title'>EMPEZAR {sanitizedText.toUpperCase()}</div>
                <div className='validar-request-dialog-list-content'>
                    {sanitizedDetail}
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='validar-request-dialog-btn-cancelar cursor-pointer' onClick={props.handleClose} >Cancelar</div>
                    </div>
                    <div className='col-6'>
                        <div className='validar-request-dialog-btn-empezar cursor-pointer' onClick={props.handleStart} >Empezar</div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ValidarRequestDialog;
