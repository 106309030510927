import { createContext, useContext, useEffect, useState } from "react";
import { General, ObtenerListas } from "../obtenerServicios";
import { StepsContext } from "./StepsProvider";
import ValidacionStepTwoHelper from "../helpers/ValidacionStepTwoHelper";
import { ModelicaLoadStepTwoData } from "../helpers/GenerarJsonHelper";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { DatosFinancierosDefault, DatosFinancierosInterface } from "../../3.50.-SolicitudeStepTwo/SolicitudeStepTwo.tsx/interfaces";
import { appConfigKey, appConfigPerfiles, appConfigSegmentos, appConfigSolicitudEtapa, appSecurityConfiguration } from "../../../../../config/Config";
import MotorTipoVehiculoConstant from "../../../../constant/MotorTipoVehiculoConstant";
import { obtenerTipoMoneda } from "../../../../helpers/ModelicaMotorHelper";
import { toast } from "sonner";
import MasterManager from "../../../../services/origination/wapimaster.service";
import JsonHelper from "../../../../helpers/JsonHelper";
import BantotalCoreHelper from "../../../../helpers/BantotalCoreHelper";
import MontoSolicitudHelper from "../helpers/MontoSolicitudHelper";
import RequestManager from "../../../../services/origination/wapirequest.service";
import WindowHelper from "../../../../helpers/WindowHelper";
import { TMnSolicitudAsociadoInsertUpdateRequest } from "../../../../models/MasterManager/solicitud-asociado-request.model";
import { ObtenerModeloSolicitudAsociado } from "../helpers/AsociadoHelper";

export const StepTwoContext = createContext<any>({});

export const StepTwoProvider = ({ children }: any) => {

    const {
        step,
        modelicaRequestBody,
        setBtnContinuarLoading,
        setClickNext,
        setResponseStepTwo,
        viewModelStepTwoFinal,
        setViewModelStepTwoFinal,
        setStep,
        setModelicaRequestBody,
        setLoadingSteps,
        clickNext,
        solicitudData,
        retrocedioAlSegundoPaso,
        listaMarcas,
        listaModelos,
        listaVersiones,
        listaConcesionarios,
        listaSucursales,
        listaUsosVehiculo,
        listaDestinoCredito,
        getObtenerSucursales,
        getObtenerVendores,
        getObtenerMarcas,
        getObtenerModelos,
        getVersiones,
        getObtenerEstadosVehiculo,
        getObtenerConcesionario,
        getObtenerUsosVehiculo,
        setListaVehiculoYears,
        getObtenerDestinoCredito,
        listaVendedores,

        listaGastos,
        viewModelStepOneFinal,
        setRequestStepTwo,
        listTipoDocumento,
        listEstadoCivil,
        listIdentidadesGenero,
        listMagnitud,
        listActividadEconomica,
        listCategoriaLaboral,
        listMoneda,
        listPais,
        listProfesion,
        listRegimen,
        listaEstadosVehiculos,
        readonlyForm,
        TCEA,
        setViewModelStepOneFinal,
        setMessageCustomerIsBlackListGesintel,
        setDetailCustomerIsBlackListGesintel,
        setReadonlyForm,
        setSolicitudData,
        concesionarioAsociado,
        vendedorAsociado,
        setValidacionModelicaDecisionRCH,
        isTitularJuridico,
    } = useContext(StepsContext);

    const [datosFinancierosViewModel, setDatosFinancierosViewModel] = useState<DatosFinancierosInterface>(DatosFinancierosDefault);
    const [newDatosFinanciamiento, setNewDatosFinanciamiento] = useState<any>(null);

    const GenerateObservacionSolicitud = async (body: any) => {
        return await General.GenerateObservacionSolicitud(body);
    };
    const GenerateActualizacionSolicitudV2 = async (body: any) => {
        return await General.GenerateActualizacionSolicitudV2(body);
    };

    const GuardarAsociados = async (partnerData: any) => {
        let successful = true;
        if (partnerData?.datosPartner) {
            let concesionarioAsociadoRequest = ObtenerModeloSolicitudAsociado(
                partnerData.datosPartner,
                concesionarioAsociado,
                newDatosFinanciamiento.Concesionario,
                newDatosFinanciamiento.ConcesionarioId,
                appConfigKey.KeyIdTipoRelacionConcesionario,
                appSecurityConfiguration.SessionUserId,
                solicitudData.idenT_SOLICITUD
            );

            const concesionarioAsociadoResponse = await General.GetMnSolicitudAsociadoInsert(concesionarioAsociadoRequest)
                .then(response => response)
                .catch(_error => null);

            if (!concesionarioAsociadoResponse) {
                successful = false;
                toast.error(appConfigKey.keyMsjErrorSolicitudPartnerActualizar, { duration: appConfigKey.keyDurationToast });
            }
        }
        if (partnerData?.datosVendedor) {
            let vendedorAsociadoRequest = ObtenerModeloSolicitudAsociado(
                partnerData.datosVendedor,
                vendedorAsociado,
                newDatosFinanciamiento.Vendedor,
                newDatosFinanciamiento.VendedorId,
                appConfigKey.KeyIdTipoRelacionVendedor,
                appSecurityConfiguration.SessionUserId,
                solicitudData.idenT_SOLICITUD
            );

            const vendedorAsociadoResponse = await General.GetMnSolicitudAsociadoInsert(vendedorAsociadoRequest)
                .then(response => response)
                .catch(_error => null);

            if (!vendedorAsociadoResponse) {
                successful = false;
                toast.error(appConfigKey.keyMsjErrorSolicitudVendedorActualizar, { duration: appConfigKey.keyDurationToast });
            }
        }

        return successful;
    }

    const DocumentacionDocumentoInsert = async (data: any, checklist_Documental: any, UsuarioId: number) => {
        if (checklist_Documental) {
            MasterManager.GetMnDocumentacionDocumentoDelete(data)
                .then((response: any) => {
                    if (response?.data.isValid) {
                        checklist_Documental?.map((documento: any) => {

                            // let documentoCoreId = BantotalCoreHelper.obtenerTipoDocumentoId(documento.codigo);
                            let documentoCoreId = documento.codigo;

                            const body = {
                                "idenT_DOCUMENTACION_DOCUMENTO": 0,
                                "idenT_SOLICITUD": solicitudData.idenT_SOLICITUD,
                                "idenT_TIPO_LISTADO": appConfigKey.KeyIdCheckListUno,
                                "descC_TIPO_LISTADO": appConfigKey.KeyDescripCheckListUno,
                                "idenT_TIPO_DOCUMENTO": documentoCoreId,
                                "descC_TIPO_DOCUMENTO": documento.descripcion,
                                "idenT_ESTADO_ADJUNTO": appConfigKey.keyIdEstadoDocumentoPorAdjuntar,
                                "descC_ESTADO_ADJUNTO": appConfigKey.keyDescripEstadoDocumentoPorAdjuntar,
                                "descC_NOMBRE_ARCHIVO": null,
                                "descC_EXTENSION_ARCHIVO": null,
                                "idenT_DOCUMENTO_BT": 0,
                                "idenT_USUARIO": UsuarioId
                            };
                            MasterManager.GetMnDocumentacionDocumentoInsert(body).then((responseInsert: any) => { });
                        });
                    }
                })
        }
    }

    const cargarViewModel = () => {

        const {
            iD_ESTADO_VEHICULO_FINANCIAR,
            idenT_CONCESIONARIO,
            idenT_SUCURSAL,
            idenT_VENDEDOR,
            descL_EMAIL_VENDEDOR,
            iD_TIPO_MONEDA_FINANCIAMIENTO,
            iD_TIPO_MONEDA_PRECIO_VEHICULO,
            iD_TIPO_MONEDA_CUOTA_INICIAL,
            monT_TIPO_CAMBIO,
            monT_PRECIO_VEHICULO,
            monT_CUOTA_INICIAL,
            iD_USO_VEHICULO,
            idenT_MARCA,
            idenT_MODELO,
            idenT_VERSION,
            monT_CALC_FINANCIAMIENTO,
            idenT_DESTINO_CREDITO,
            idenT_ANIO_VEHICULO,
            inD_BONO,
            segmento,
            monT_PRECIO_VEHICULO_BONO,
            monT_CUOTA_INICIAL_BONO,
            monT_TIPO_CAMBIO_BASE,
            iD_TIPO_MONEDA_PRECIO_VEHICULO_BASE,
            monT_PRECIO_VEHICULO_BASE,
            iD_TIPO_MONEDA_CUOTA_INICIAL_BASE,
            monT_CUOTA_INICIAL_BASE,
        } = solicitudData;

        const concesionarioId = Number(idenT_CONCESIONARIO);
        const sucursalId = Number(idenT_SUCURSAL);
        const vendedorId = Number(idenT_VENDEDOR);
        const marcaId = Number(idenT_MARCA);
        const modeloId = Number(idenT_MODELO);

        getObtenerSucursales(concesionarioId);
        getObtenerVendores(concesionarioId, sucursalId);
        getObtenerMarcas(sucursalId, vendedorId, concesionarioId);
        getObtenerModelos(marcaId, sucursalId, vendedorId, concesionarioId);
        getVersiones(modeloId, sucursalId, vendedorId, concesionarioId);

        let montoPrecioBaseSoles: any = "";
        let montoPrecioBaseDolares: any = "";
        let montoInicialBaseSoles: any = "";
        let montoInicialBaseDolares: any = "";
        let montoFinanciarBaseSoles: any = "";
        let montoFinanciarBaseDolares: any = "";

        let montoPrecioSoles: any = "";
        let montoPrecioDolares: any = "";
        let montoInicialSoles: any = "";
        let montoInicialDolares: any = "";
        let montoFinanciarSoles: any = "";
        let montoFinanciarDolares: any = "";

        let tipoCambio = Number(monT_TIPO_CAMBIO);
        let montoPrecioVehiculoSoles: number = 0;
        let montoCuotaInicialSoles: number = 0;

        if (monT_PRECIO_VEHICULO_BONO > 0) {
            const precioVehiculoBono = Number(monT_PRECIO_VEHICULO_BONO);
            montoPrecioVehiculoSoles = iD_TIPO_MONEDA_FINANCIAMIENTO === appConfigKey.keyIdentificadorDolares
                ? precioVehiculoBono * tipoCambio
                : precioVehiculoBono;
        } else {
            const precioVehiculo = Number(monT_PRECIO_VEHICULO);
            montoPrecioVehiculoSoles = iD_TIPO_MONEDA_PRECIO_VEHICULO === appConfigKey.keyIdentificadorDolares
                ? precioVehiculo * tipoCambio
                : precioVehiculo;
        }

        if (monT_CUOTA_INICIAL_BONO > 0) {
            const cuotaInicialBono = Number(monT_CUOTA_INICIAL_BONO);
            montoCuotaInicialSoles = iD_TIPO_MONEDA_FINANCIAMIENTO === appConfigKey.keyIdentificadorDolares
                ? cuotaInicialBono * tipoCambio
                : cuotaInicialBono;
        } else {
            const cuotaInicial = Number(monT_CUOTA_INICIAL);
            montoCuotaInicialSoles = iD_TIPO_MONEDA_CUOTA_INICIAL === appConfigKey.keyIdentificadorDolares
                ? cuotaInicial * tipoCambio
                : cuotaInicial;
        }

        montoPrecioBaseSoles = (Number(monT_PRECIO_VEHICULO_BASE) * Number(monT_TIPO_CAMBIO_BASE)).toFixed(2);
        montoPrecioBaseDolares = Number(monT_PRECIO_VEHICULO_BASE).toFixed(2);
        montoInicialBaseSoles = (Number(monT_CUOTA_INICIAL_BASE) * Number(monT_TIPO_CAMBIO_BASE)).toFixed(2);
        montoInicialBaseDolares = Number(monT_CUOTA_INICIAL_BASE).toFixed(2);

        montoPrecioSoles = (Number(monT_PRECIO_VEHICULO) * Number(monT_TIPO_CAMBIO)).toFixed(2);
        montoPrecioDolares = Number(monT_PRECIO_VEHICULO).toFixed(2);
        montoInicialSoles = (Number(monT_CUOTA_INICIAL) * Number(monT_TIPO_CAMBIO)).toFixed(2);
        montoInicialDolares = Number(monT_CUOTA_INICIAL).toFixed(2);

        montoFinanciarBaseSoles = montoPrecioBaseSoles - montoInicialBaseSoles;
        montoFinanciarBaseDolares = montoPrecioBaseDolares - montoInicialBaseDolares;

        montoFinanciarSoles = montoPrecioSoles - montoInicialSoles;
        montoFinanciarDolares = montoPrecioDolares - montoInicialDolares;

        setDatosFinancierosViewModel({
            ...datosFinancierosViewModel,
            EstadoVehiculoId: Number(iD_ESTADO_VEHICULO_FINANCIAR),
            ConcesionarioId: concesionarioId,
            SucursalId: sucursalId,
            VendedorId: vendedorId,
            VendedorEmail: descL_EMAIL_VENDEDOR,
            MonedaId: iD_TIPO_MONEDA_FINANCIAMIENTO,
            TipoCambioBase: monT_TIPO_CAMBIO_BASE?.toFixed(2),
            TipoCambio: monT_TIPO_CAMBIO.toFixed(2),
            MontoVehiculoBaseSoles: GeneralHelper.AgregarComasAMiles(montoPrecioBaseSoles),
            MontoVehiculoBaseDolares: GeneralHelper.AgregarComasAMiles(montoPrecioBaseDolares),
            MontoInicialBaseSoles: GeneralHelper.AgregarComasAMiles(montoInicialBaseSoles),
            MontoInicialBaseDolares: GeneralHelper.AgregarComasAMiles(montoInicialBaseDolares),
            InicialBase: ((montoInicialBaseSoles / montoPrecioBaseSoles) * 100).toFixed(2),
            MontoVehiculoSoles: GeneralHelper.AgregarComasAMiles(montoPrecioSoles),
            MontoVehiculoDolares: GeneralHelper.AgregarComasAMiles(montoPrecioDolares),
            MontoInicialSoles: GeneralHelper.AgregarComasAMiles(montoInicialSoles),
            MontoInicialDolares: GeneralHelper.AgregarComasAMiles(montoInicialDolares),
            Inicial: ((montoInicialSoles / montoPrecioSoles) * 100),
            InicialValue: ((montoInicialBaseSoles / montoPrecioBaseSoles) * 100).toFixed(2),
            UsoVehiculoId: Number(iD_USO_VEHICULO),
            MarcaId: marcaId,
            ModeloId: modeloId,
            VersionId: Number(idenT_VERSION),
            MontoFinanciarBase: GeneralHelper.AgregarComasAMiles(montoFinanciarBaseSoles.toFixed(2)),
            MontoFinanciarBaseDolares: GeneralHelper.AgregarComasAMiles(montoFinanciarBaseDolares.toFixed(2)),
            MontoFinanciar: GeneralHelper.AgregarComasAMiles(montoFinanciarSoles.toFixed(2)),
            MontoFinanciarDolares: GeneralHelper.AgregarComasAMiles(montoFinanciarDolares.toFixed(2)),
            DestinoCreditoId: Number(idenT_DESTINO_CREDITO),
            VehiculoYear: Number(idenT_ANIO_VEHICULO),
            AplicaBono: true,
            Segmento: segmento
        } as DatosFinancierosInterface);

    };

    const primeraCargar = () => {

        if (retrocedioAlSegundoPaso && !readonlyForm) {
            setDatosFinancierosViewModel(viewModelStepTwoFinal);
            return;
        }

        if (!readonlyForm) {
            cargarViewModel();
            setListaVehiculoYears(ObtenerListas.AniosVehiculo);
            getObtenerEstadosVehiculo();
            getObtenerConcesionario();
            getObtenerUsosVehiculo();
            getObtenerDestinoCredito();
            return;
        }

    };

    const resetSendClick = () => {
        setClickNext(0);
        setNewDatosFinanciamiento(null);
        setBtnContinuarLoading(false);
        setLoadingSteps(false);
    };

    const obtenerListaGastos = (gastos: any) => {
        const detalle_gastos: any = [];

        gastos.map((gasto: any) => {
            if (Boolean(gasto.inD_SELECCION)) {
                detalle_gastos.push({
                    "codigo": gasto.iD_TIPO_GASTO,
                    "descripcion": gasto.descC_TIPO_GASTO,
                    "monto": gasto.monT_GASTO
                });
            }
        });

        const total_gastos = gastos.reduce((acumulador: number, elemento: any) => {
            return acumulador + elemento.monT_GASTO;
        }, 0);

        return {
            detalle_gastos,
            total_gastos
        };
    };

    const asignarDescripciones = (newDatosFinanciamiento: any) => {
        let modelo: any = listaModelos.find((x: any) => x.id === newDatosFinanciamiento.ModeloId);
        let marca: any = listaMarcas.find((x: any) => x.id === newDatosFinanciamiento.MarcaId);
        let version: any = listaVersiones.find((x: any) => x.id === newDatosFinanciamiento.VersionId);
        let concesionario: any = listaConcesionarios.find((x: any) => x.id === newDatosFinanciamiento.ConcesionarioId);
        let sucursal: any = listaSucursales.find((x: any) => x.id === newDatosFinanciamiento.SucursalId);
        let usosVehiculo: any = listaUsosVehiculo.find((x: any) => x.id === newDatosFinanciamiento.UsoVehiculoId);
        let vendedor: any = listaVendedores.find((x: any) => x.id === newDatosFinanciamiento.VendedorId);
        let destinoCredito: any = listaDestinoCredito.find((x: any) => x.id === newDatosFinanciamiento.DestinoCreditoId);

        newDatosFinanciamiento.Sucursal = sucursal?.description;
        newDatosFinanciamiento.Marca = marca?.description;
        newDatosFinanciamiento.Concesionario = concesionario?.description;
        newDatosFinanciamiento.UsosVehiculo = usosVehiculo?.description;
        newDatosFinanciamiento.Modelo = modelo?.description;
        newDatosFinanciamiento.Vendedor = vendedor?.description;
        newDatosFinanciamiento.Version = version?.description;
        newDatosFinanciamiento.DestinoCredito = destinoCredito?.description;
    }

    const continuarSiguientePaso = async () => {

        if (clickNext !== 0 && newDatosFinanciamiento != null) {

            if (readonlyForm) {
                asignarDescripciones(newDatosFinanciamiento);
                setViewModelStepTwoFinal(newDatosFinanciamiento);
                setStep(step + 1);
                resetSendClick();
                return;
            }

            const validarNewDatosFinanciamiento = !ValidacionStepTwoHelper.NewDatosFinanciamiento(newDatosFinanciamiento);

            if (validarNewDatosFinanciamiento) {
                resetSendClick();
                return;
            }

            const convertirTextoANumber = (montoString: string) => {
                const montoSinComas: string = GeneralHelper.QuitarComasAMiles(montoString);
                return Number(montoSinComas);
            };

            const montoFinanciarDolares = () => {
                const montoFinanciarSoles = convertirTextoANumber(newDatosFinanciamiento.MontoFinanciar);
                const value = montoFinanciarSoles / Number(newDatosFinanciamiento.TipoCambio);
                return Number(value.toFixed(2));
            };

            const cuotaIniciaMonto = newDatosFinanciamiento.MonedaId === 1 ? convertirTextoANumber(newDatosFinanciamiento.MontoInicialSoles) : convertirTextoANumber(newDatosFinanciamiento.MontoInicialDolares);
            const montoFinanciar = newDatosFinanciamiento.MonedaId === 1 ? convertirTextoANumber(newDatosFinanciamiento.MontoFinanciar) : montoFinanciarDolares();

            asignarDescripciones(newDatosFinanciamiento);

            const gastos = obtenerListaGastos(listaGastos);

            let tempTipoVehiculo = 0;

            const findTipoVehiculo = MotorTipoVehiculoConstant.find((item: any) => {
                return item.TipoVehiculoCoreUid === Number(newDatosFinanciamiento.EstadoVehiculoId);
            });

            if (findTipoVehiculo !== undefined) {
                tempTipoVehiculo = findTipoVehiculo.TipoVehiculoMotorUid;
            }

            const listasTemp = {
                listTipoDocumento,
                listEstadoCivil,
                listIdentidadesGenero,
                listMagnitud,
                listActividadEconomica,
                listCategoriaLaboral,
                listMoneda,
                listPais,
                listProfesion,
                listRegimen,
                listaEstadosVehiculos
            };

            const RequestGenerateActualizacionSolicitudV2 = JsonHelper.crearJsonRequestGenerateActualizacionSolicitudV2(
                step,
                solicitudData,
                viewModelStepOneFinal.newTitularData,
                viewModelStepOneFinal.newConyugeData,
                viewModelStepOneFinal.newIngresosTitularData,
                viewModelStepOneFinal.newIngresosConyugeData,
                viewModelStepOneFinal.newPersonaCompraParaData,
                viewModelStepOneFinal.newPersonaCompraParaConyugeData,
                viewModelStepOneFinal.newFiadorData,
                viewModelStepOneFinal.newObservacionData,
                newDatosFinanciamiento,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                listasTemp,
                appSecurityConfiguration.SessionUserId,
                TCEA,
                isTitularJuridico,
                null,
                null,
            )

            // Guardar datos de asociados (concesionario y vendedor)
            const partnerData: any = null; //Fix temporal - PRD

            // const partnerData = await General.ObtenerDatosPartnerVendedor({
            //     idPartner: newDatosFinanciamiento.ConcesionarioId,
            //     idVendedor: newDatosFinanciamiento.VendedorId
            // })
            //     .then(data => data)
            //     .catch(e => null);

            // if (!partnerData) {
            //     toast.error(appConfigKey.keyMsjErrorSolicitudPartnerPorValidar, { duration: appConfigKey.keyDurationToast });
            //     resetSendClick();
            //     return;
            // }

            // const successful = await GuardarAsociados(partnerData);
            // if (!successful) return;

            // Lista negra y gesintel
            if (appSecurityConfiguration.SessionPerfil === appConfigPerfiles.CodigoEjecutivo ||
                appSecurityConfiguration.SessionPerfil === appConfigPerfiles.CodigoJefeComercial) {

                const executiveData = await General.GetUsuario(appSecurityConfiguration.SessionUserId)
                    .then(data => data)
                    .catch(e => null);

                if (!executiveData) {
                    toast.error(appConfigKey.keyMsjErrorSolicitudGenericoPorValidar.replace('[CAMPO]', appSecurityConfiguration.SessionPerfil === appConfigPerfiles.CodigoEjecutivo ? 'Ejecutivo' : 'Jefe Comercial'), { duration: appConfigKey.keyDurationToast });
                    resetSendClick();
                    return;
                }

                let validateMembers = ValidacionStepTwoHelper.GetMembersStepTwoData(
                    solicitudData,
                    executiveData,
                    partnerData,
                    newDatosFinanciamiento,
                    viewModelStepOneFinal.newTitularData,
                    viewModelStepOneFinal.newConyugeData,
                    viewModelStepOneFinal.newIngresosTitularData,
                    viewModelStepOneFinal.newIngresosConyugeData,
                    viewModelStepOneFinal.newPersonaCompraParaData,
                    viewModelStepOneFinal.newPersonaCompraParaConyugeData,
                    viewModelStepOneFinal.newFiadorData
                );

                const personaPorValidarRequest = JsonHelper.crearJsonSolicitudPersonaPorValidar(solicitudData, appSecurityConfiguration.SessionUserId, validateMembers);
                const personaPorValidarResponse = await General.GetMnSolicitudPersonaPorValidar(personaPorValidarRequest);

                if (!personaPorValidarResponse || personaPorValidarResponse?.status !== 200 || !personaPorValidarResponse?.data?.isValid) {
                    toast.error(appConfigKey.keyMsjErrorSolicitudPersonaPorValidar, { duration: appConfigKey.keyDurationToast });
                    resetSendClick();
                    return;
                }

                let nroPasada = (personaPorValidarResponse?.data?.content?.ultimoNroPasada ?? 0) + 1;
                let miembrosPorValidar = ValidacionStepTwoHelper.GetMembersEquals(validateMembers, personaPorValidarResponse?.data?.content?.miembros ?? []);
                let situacionPlaft = solicitudData?.idenT_SITUACION_PLAFT ?? 0;
                let situacionRiesgos = solicitudData?.idenT_SITUACION_RIESGOS ?? 0;

                //Validar Lista negra y gesintel
                if (
                    (situacionPlaft === 0 || situacionPlaft !== appConfigKey.CodigoSituacionPlaftRiesgosLiberada) &&
                    (situacionRiesgos === 0 || situacionRiesgos !== appConfigKey.CodigoSituacionPlaftRiesgosLiberada) ||
                    (miembrosPorValidar.length > 0)
                ) {

                    let blackListGesintel: any = [];
                    let blackGesintel: any = null;
                    for (let index = 0; index < miembrosPorValidar.length; index++) {
                        let member = miembrosPorValidar[index];

                        const validateMemberResult = await General.ValidarListaNegraYGesintel({
                            tipoDocumento: member.TipoDocumento,
                            numeroDocumento: member.NumeroDocumento?.trim(),
                            razonSocial: member.RazonSocial?.trim()
                        });

                        if (validateMemberResult) {

                            if (validateMemberResult.errorBantotal || validateMemberResult.errorGesintel) {
                                let msgError = (validateMemberResult.errorBantotal && validateMemberResult.errorGesintel) ? appConfigKey.keyMsjErrorListaNegraYGesintel
                                    : validateMemberResult.errorBantotal ? appConfigKey.keyMsjErrorListaNegra
                                        : appConfigKey.keyMsjErrorGesintel
                                toast.error(msgError, { duration: appConfigKey.keyDurationToast });
                                resetSendClick();
                                return;
                            }

                            blackGesintel = {
                                tipoDocumento: member.TipoDocumento,
                                numeroDocumento: member.NumeroDocumento,
                                tipoRelacionId: member.TipoRelacionId,
                                bantotalJson: validateMemberResult.bantotalJson,
                                gesintelJson: validateMemberResult.gesintelJson,
                                clientePep: validateMemberResult.clientePep,
                                esObservadoPlaftYRiesgo: false,
                                esObservadoRiesgos: false,
                                esObservadoPlaft: false,
                                bantotalObservado: false,
                                gesintelObservado: false
                            }

                            if (validateMemberResult.bantotalPlaftObservado || validateMemberResult.bantotalRiesgosObservado) {
                                // Observado por Bantotal
                                blackGesintel.bantotalObservado = true;
                            }

                            if (validateMemberResult.gesintelPlaftObservado) {
                                // Observado por Gesintel
                                blackGesintel.gesintelObservado = true;
                            }

                            if ((validateMemberResult.bantotalPlaftObservado || validateMemberResult.gesintelPlaftObservado) &&
                                validateMemberResult.bantotalRiesgosObservado) {
                                // Observado por Gesintel (Observado Plaft) y BanTotal (Observado Riesgos)     
                                blackGesintel.esObservadoPlaftYRiesgo = true;
                                blackListGesintel.push(blackGesintel);

                            } else if (validateMemberResult.bantotalPlaftObservado || validateMemberResult.gesintelPlaftObservado) {
                                // Observado por Gesintel (Observado Plaft)
                                blackGesintel.esObservadoPlaft = true;
                                blackListGesintel.push(blackGesintel);

                            } else if (validateMemberResult.bantotalRiesgosObservado) {
                                // Observado por BanTotal (Observado Riesgos)
                                blackGesintel.esObservadoRiesgos = true;
                                blackListGesintel.push(blackGesintel);
                            }

                            if (blackGesintel.clientePep && member.TipoRelacionId === appConfigKey.KeyIdTipoRelacionTitular) {
                                setViewModelStepOneFinal((state: any, props: any) => {
                                    let newTitularData = state?.newTitularData;
                                    newTitularData.ClientePep = blackGesintel.clientePep;
                                    return ({
                                        ...state,
                                        newTitularData
                                    });
                                });
                                // newTitularData.ClientePep = blackGesintel.clientePep
                                // setTitularData(newTitularData);
                            }

                        } else {
                            setMessageCustomerIsBlackListGesintel(null);
                            setDetailCustomerIsBlackListGesintel(null);
                            resetSendClick();
                            return;
                        }
                    }

                    setMessageCustomerIsBlackListGesintel(null);
                    setDetailCustomerIsBlackListGesintel(null);

                    if (blackListGesintel.length > 0) {

                        const validacionNewBlackListGesintel = ValidacionStepTwoHelper.NewBlackListGesintel({ blackListGesintel });

                        if (!validacionNewBlackListGesintel) {
                            resetSendClick();
                            return;
                        }

                        setMessageCustomerIsBlackListGesintel(validacionNewBlackListGesintel.mensaje);
                        setDetailCustomerIsBlackListGesintel(validacionNewBlackListGesintel.detalle);

                        const RequestGenerateObservacionSolicitud = JsonHelper.crearJsonRequestGenerateObservacionSolicitud(
                            solicitudData,
                            executiveData,
                            partnerData,
                            viewModelStepOneFinal.newTitularData,
                            viewModelStepOneFinal.newConyugeData,
                            viewModelStepOneFinal.newIngresosTitularData,
                            viewModelStepOneFinal.newIngresosConyugeData,
                            viewModelStepOneFinal.newPersonaCompraParaData,
                            viewModelStepOneFinal.newPersonaCompraParaConyugeData,
                            viewModelStepOneFinal.newFiadorData,
                            viewModelStepOneFinal.newObservacionData,
                            validacionNewBlackListGesintel,
                            blackListGesintel,
                            listasTemp,
                            appSecurityConfiguration.SessionUserId,
                            nroPasada,
                            appConfigSolicitudEtapa.EvaluacionCrediticiaMotor,
                        )
                        let updateSuccess = await GenerateActualizacionSolicitudV2(RequestGenerateActualizacionSolicitudV2).then(async (response) => {
                            if (response?.status == 200 && response?.data?.isValid && response?.data?.content) {
                                // const solicitudUpdated = response.data.content;
                                // setSolicitudData(solicitudUpdated);
                                return true
                            } else {
                                return false
                            }
                        });

                        if (!updateSuccess) {
                            toast.error(appConfigKey.keyMsjErrorGuardarSolicitud, { duration: appConfigKey.keyDurationToast });
                            resetSendClick();
                            return;
                        }

                        var whilelist = false;

                        updateSuccess = await GenerateObservacionSolicitud(RequestGenerateObservacionSolicitud).then(async (response) => {                            
                            if (response?.status == 200 && response?.data?.isValid && response?.data?.content) {
                                const observacionSolicitud = response.data.content.resultTMnSolicitudAuditDto.content[0];                                
                                if(observacionSolicitud.iD_ESTADO_SOLICITUD === appConfigKey.KeyIdEstadoSolicitudRegistrado){
                                    setMessageCustomerIsBlackListGesintel(null);
                                    setDetailCustomerIsBlackListGesintel(null);
                                    whilelist = true;
                                    return true;
                                }
                                else{
                                    return true
                                }                                
                            } else {
                                return false
                            }
                        });                        

                        if (!updateSuccess) {
                            toast.error(appConfigKey.keyMsjErrorGuardarSolicitudObservada, { duration: appConfigKey.keyDurationToast });
                            setMessageCustomerIsBlackListGesintel(null);
                            setDetailCustomerIsBlackListGesintel(null);
                            resetSendClick();
                            return;
                        }

                        if(whilelist) {
                            setReadonlyForm(false);
                        }
                        else{
                            setReadonlyForm(true);
                        }
                        WindowHelper.ScrollToTop();
                        resetSendClick();
                        return;
                    }
                };
            }

            if (!isTitularJuridico) {
                const requestBody = ModelicaLoadStepTwoData(modelicaRequestBody, {
                    CuotaInicial: {
                        MonedaId: Number(newDatosFinanciamiento.MonedaId),
                        Monto: cuotaIniciaMonto,
                        Porcentaje: GeneralHelper.ParseTwoDecimal(newDatosFinanciamiento.Inicial)
                    },
                    Modelo: newDatosFinanciamiento.Modelo,
                    Marca: newDatosFinanciamiento.Marca,
                    Version: newDatosFinanciamiento.Version,
                    TipoCambio: Number(newDatosFinanciamiento.TipoCambio),
                    ValorVehiculoUSD: convertirTextoANumber(newDatosFinanciamiento.MontoVehiculoDolares),
                    FabricacionYear: String(newDatosFinanciamiento.VehiculoYear),
                    MontoFinanciar: montoFinanciar,
                    MonedaFinanciamiento: Number(newDatosFinanciamiento.MonedaId),
                    TipoVehiculo: tempTipoVehiculo,
                    Gastos: gastos,
                    AplicaBono: newDatosFinanciamiento.AplicaBono ? 1 : 0
                });

                setRequestStepTwo(requestBody);

                const modelicaSuccess = await General.Modelica(requestBody).then(async (response: any) => {

                    const jsonResponseModelica = JSON.parse(response.data.content);

                    setModelicaRequestBody(requestBody);

                    let decision = jsonResponseModelica?.response?.Politica_Creditos?.decision_Credito?.decision;
                    let mesaCreditos = jsonResponseModelica?.response?.Politica_Creditos?.decision_Credito?.mesa_Creditos;

                    /*if (decision) {
    
                        let identResultado: number = 0;
                        if (decision === appConfigKey.CodigoEvaluacionAprobado) {
                            identResultado = appConfigKey.keyIdResultadoAprobado
                        }
                        if (decision === appConfigKey.CodigoEvaluacionRechazado) {
                            identResultado = appConfigKey.keyIdResultadoRechazado
                        }
                        if (decision === appConfigKey.CodigoEvaluacionZonaGris) {
                            identResultado = appConfigKey.keyIdResultadoZonaGris
                        }
    
                        General.GetMnResultadoEvaluacionInsert(
                            {
                                "idenT_SOLICITUD": solicitudData.idenT_SOLICITUD,
                                "idenT_RESULTADO": identResultado,
                                "idenT_TIPO_RESULTADO": appConfigKey.KeyIdTipoResultadoSinObservaciones,
                                "idenT_DESTINO_CREDITO": newDatosFinanciamiento.DestinoCreditoId,
                                "idenT_USUARIO_CREADOR": appSecurityConfiguration.SessionUserId
                            }
                        );
                    }*/

                    General.GetMnEvaluacionSolicitudInsert(
                        solicitudData.idenT_SOLICITUD,
                        appConfigKey.EvaluacionModelicaStepTwo,
                        JSON.stringify(requestBody),
                        JSON.stringify(jsonResponseModelica)
                    );

                    setResponseStepTwo(jsonResponseModelica);

                    let validacionModelicaDecision = ValidacionStepTwoHelper.ModelicaDecision(response);

                    setValidacionModelicaDecisionRCH('');
                    if (decision === appConfigKey.CodigoEvaluacionRechazado) {
                        if (validacionModelicaDecision) {
                            toast.error(validacionModelicaDecision, { duration: appConfigKey.keyDurationToast });
                            setValidacionModelicaDecisionRCH(validacionModelicaDecision);
                        }
                    }

                    if (mesaCreditos.derivacion === appConfigKey.CodigoDerivacion) {

                        if (validacionModelicaDecision) {
                            toast.info(validacionModelicaDecision, { duration: appConfigKey.keyDurationToast });
                        }

                        const { productos_comerciales } = jsonResponseModelica?.response;

                        if (productos_comerciales === undefined || productos_comerciales === null) {
                            toast.error("No cuenta con productos comerciales", { duration: appConfigKey.keyDurationToast });
                            return false;
                        }

                        if (productos_comerciales.length === 0) {
                            toast.error("No cuenta con productos comerciales", { duration: appConfigKey.keyDurationToast });
                            return false;
                        }
                    }

                    DocumentacionDocumentoInsert({
                        IDENT_SOLICITUD: solicitudData.idenT_SOLICITUD,
                        IDENT_TIPO_LISTADO: appConfigKey.KeyIdCheckListUno
                    },
                        jsonResponseModelica.response?.checklist_Documental,
                        appSecurityConfiguration.SessionUserId);

                    let segmentoId: string = jsonResponseModelica?.response?.Politica_Creditos?.decision_Credito?.segmento ?? ''
                    newDatosFinanciamiento.Segmento = appConfigSegmentos[segmentoId];


                    return true;
                }).catch((error: any) => {
                    return false;
                });

                setLoadingSteps(false);
                if (!modelicaSuccess) {
                    resetSendClick();
                    return;
                }
            }

            const updateSuccess = await GenerateActualizacionSolicitudV2(RequestGenerateActualizacionSolicitudV2).then(async (response) => {
                if (response?.status == 200 && response?.data?.isValid && response?.data?.content) {
                    return true
                } else {
                    return false
                }
            });

            if (!updateSuccess) {
                toast.error(appConfigKey.keyMsjErrorGuardarSolicitud, { duration: appConfigKey.keyDurationToast });
                resetSendClick();
                return;
            }

            setViewModelStepTwoFinal(newDatosFinanciamiento);
            setStep(step + 1);

            setClickNext(0);
            setNewDatosFinanciamiento(null);
            setBtnContinuarLoading(false);
        }
    };

    useEffect(() => primeraCargar(), []);
    useEffect(() => { continuarSiguientePaso() }, [newDatosFinanciamiento, clickNext]);

    return (
        <StepTwoContext.Provider value={{
            setNewDatosFinanciamiento,
            datosFinancierosViewModel,
        }}>
            {children}
        </StepTwoContext.Provider>
    );
};