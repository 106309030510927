import * as React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { Select, MenuItem } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  appConfigKey,
  appSecurityConfiguration,
} from "../../../../config/Config";
import MasterManager from "../../../services/origination/wapimaster.service";
import {
  Listado,
  ResultadoEvaluacionDto,
  ResultadoEvalucionDtoDefault,
} from "../../../models/ManagerProfile/ResultadoEvaluacion";
import { Button, Input, Textarea } from "@mui/joy";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ReportManager from "../../../services/support/wapireport";
import { Toaster, toast } from "sonner";
import ValidacionResultadosEvaluacionHelper from "../13.10.-base/helpers/ValidacionResultadosEvaluacionHelper";
import LoadingProgress from "../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import {
  keyCreditosConsumoNoRevolvente,
  keyDeudorMinorista,
} from "./constants";
import GeneralHelper from "../../../helpers/GeneralHelper";

const ResultadosEvaluacion = ({
  handleNewResultadoHistorico,
  solicitud,
  esModoLectura,
  reqResultadoEvaluacion,
  setReqResultadoEvaluacion,
  flgResultadosHistorial,
  titular,
  isVisibleAprobadoConDocumentacion,
  isVisibleAprobado,
}: any) => {
  const { solicitudId } = useParams();
  const [resultado, setResultado] = React.useState<Listado[]>([]);

  const [
    listaPrimerNivelTipoResultadoAprobado,
    setListaPrimerNivelTipoResultadoAprobado,
  ] = React.useState<any[]>([]);
  const [
    listaPrimerNivelTipoResultadoObservado,
    setListaPrimerNivelTipoResultadoObservado,
  ] = React.useState<any[]>([]);
  const [
    listaPrimerNivelTipoResultadoRechazado,
    setListaPrimerNivelTipoResultadoRechazado,
  ] = React.useState<any[]>([]);
  const [listaCreditoTipos, setListaCreditoTipos] = React.useState<any[]>([]);
  const [listaCreditoSubtipos, setListaCreditoSubtipos] = React.useState<any[]>(
    []
  );

  const [resultadoEvaluacionActual, setResultadoEvaluacionActual] =
    React.useState<any>(null);

  const [destinoCredito, setDestinoCredito] = React.useState<Listado[]>([]);
  //const [reqResultadoEvaluacion, setReqResultadoEvaluacion] = React.useState<ResultadoEvaluacionDto>(ResultadoEvalucionDtoDefault)
  const [loadingResulEva, setLoadingResulEva] = React.useState(false);
  const [esModoLecturaResulEva, setEsModoLecturaResulEva] =
    React.useState(false);
  const [opcionesTipoDeResultado, setOpcionesTipoDeResultado] =
    React.useState<any>([]);

  const cargarListaResultado = async () => {
    var body = {
      v_LIST_IDENT_TABLA: "18,40,41,42,43,44",
    };

    const listResultado: any[] = [];
    const listPrimerNivelTipoResultadoAprobado: any[] = [];
    const listPrimerNivelTipoResultadoObservado: any[] = [];
    const listPrimerNivelTipoResultadoRechazado: any[] = [];
    const listCreditoTipos: any[] = [];
    const listCreditoSubtipos: any[] = [];

    await MasterManager.GetMnTablaGenericaDefinicion(body)
      .then((response: any) => {
        const { content, isValid } = response.data;
        let contentTemp: any = content;

        if (!isValid) {
          toast.error("Ocurrió un error con el servicio.");
          return;
        }

        if (!isVisibleAprobadoConDocumentacion) {
          contentTemp = content.filter((x: any) => x.genericA_IDENT_CAMPO !== appConfigKey.KeyIdResultadoAprobadoConDocumentos)
        }

        if (!isVisibleAprobado) {
          contentTemp = content.filter((x: any) => x.genericA_IDENT_CAMPO !== appConfigKey.KeyIdResultadoAprobado)
        }

        contentTemp?.map((item: any, index: any) => {
          if (item.definicioN_IDENT_TABLA === 18) {


            listResultado.push({
              id: item.genericA_IDENT_CAMPO,
              label: item.genericA_DESCM_CAMPO,
              key: index,
            });

          }

          if (item.definicioN_IDENT_TABLA === 40) {
            listPrimerNivelTipoResultadoAprobado.push({
              value: item.genericA_IDENT_CAMPO,
              label: item.genericA_DESCM_CAMPO,
              key: index,
            });
          }

          if (item.definicioN_IDENT_TABLA === 41) {
            listPrimerNivelTipoResultadoObservado.push({
              value: item.genericA_IDENT_CAMPO,
              label: item.genericA_DESCM_CAMPO,
              key: index,
            });
          }

          if (item.definicioN_IDENT_TABLA === 42) {
            listPrimerNivelTipoResultadoRechazado.push({
              value: item.genericA_IDENT_CAMPO,
              label: item.genericA_DESCM_CAMPO,
              key: index,
            });
          }

          if (item.definicioN_IDENT_TABLA === 43) {
            listCreditoTipos.push({
              value: item.genericA_IDENT_CAMPO,
              label: item.genericA_DESCM_CAMPO,
              key: index,
              detalle: item.detallE_CAMPO,
            });
          }

          if (item.definicioN_IDENT_TABLA === 44) {
            listCreditoSubtipos.push({
              value: item.genericA_IDENT_CAMPO,
              label: item.genericA_DESCM_CAMPO,
              key: index,
            });
          }
        });

        setResultado(listResultado);
        setListaPrimerNivelTipoResultadoAprobado(
          listPrimerNivelTipoResultadoAprobado
        );
        setListaPrimerNivelTipoResultadoObservado(
          listPrimerNivelTipoResultadoObservado
        );
        setListaPrimerNivelTipoResultadoRechazado(
          listPrimerNivelTipoResultadoRechazado
        );
        setListaCreditoTipos(listCreditoTipos);
        setListaCreditoSubtipos(listCreditoSubtipos);
      })
      .catch((error) => {
        toast.error("Ocurrió un error con el servicio.");
      });
  };

  const descargarReporte = async (
    solicitudId: number,
    usuarioId: number,
    reporteId: number,
    bpo: boolean
  ) => {
    try {
      const responseObtenerReporte = await ReportManager.ObtenerReporte({
        idSolicitud: solicitudId,
        idUsuario: usuarioId,
        idReporte: reporteId,
        indBPO: bpo,
      });

      if (responseObtenerReporte.status === 200) {
        return responseObtenerReporte;
      }
    } catch (error) {
      console.log("Ocurrió un error al asociar el documento");
      return null;
    }
  };

  const asociarDocumentoDigital = async (
    documentoId: any,
    tipoDocumentoId: number,
    nombre: any,
    archivoCodificado: any,
    fechaEmision: any,
    fechaVencimiento: any,
    estadoAdjunto: number,
    fulldata: any,
    operacionId: number
  ) => {
    var responseAsociarDocumentoDigital =
      await ServicesProvider.AsociarDocumentoDigital(
        {
          tipoDocumentoId,
          nombre,
          archivoCodificado,
          fechaEmision,
          fechaVencimiento,
        },
        operacionId
      );
    
    const msgError = GeneralHelper.ValidarCargaDocumento(responseAsociarDocumentoDigital, true);
    if (msgError) {
      toast.error(msgError, {
        duration: appConfigKey.keyDurationToast,
      });
      return;
    }
    
    const body = {
      idenT_DOCUMENTACION_DOCUMENTO: 0,
      idenT_SOLICITUD: solicitudId,
      idenT_TIPO_LISTADO: 2,
      descC_TIPO_LISTADO: "CHECKLIST2",
      idenT_TIPO_DOCUMENTO: 65,
      descC_TIPO_DOCUMENTO: "Carta Confirmación",
      idenT_ESTADO_ADJUNTO: appConfigKey.keyIdEstadoDocumentoAdjuntado,
      descC_ESTADO_ADJUNTO: appConfigKey.keyDescripEstadoDocumentoAdjuntado,
      descC_NOMBRE_ARCHIVO: nombre,
      descC_EXTENSION_ARCHIVO: ".pdf",
      idenT_DOCUMENTO_BT:
        responseAsociarDocumentoDigital?.data?.content?.documentoId,
      idenT_USUARIO: appSecurityConfiguration.SessionUserId,
    };
    await MasterManager.GetMnDocumentacionDocumentoInsert(body);
  };

  const generarDescargaContrato = async (
    solicitudId: number,
    usuarioId: number,
    reporteId: number,
    bpo: boolean,
    data: any
  ) => {
    const response: any = await descargarReporte(
      solicitudId,
      usuarioId,
      reporteId,
      bpo
    );
    if (!response) {
      console.log("Error al descargar el documento.");
      return;
    }

    function obtenerFechaActual(addYear: number) {
      const fecha = new Date();
      const anio = fecha.getFullYear();
      const mes = String(fecha.getMonth() + 1).padStart(2, "0"); // El mes es zero-based, por lo que se suma 1
      const dia = String(fecha.getDate()).padStart(2, "0");
      const fechaActual = `${anio + addYear}-${mes}-${dia}`;
      return fechaActual;
    }

    function fileToBase64(file: any) {
      return new Promise<string>((resolve, reject) => {
        const reader: any = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64String: string = reader.result.split(",")[1];
          resolve(base64String);
        };
        reader.onerror = (error: any) => reject(error);
      });
    }

    var fileName = null;
    var header = response?.headers["content-disposition"];
    if (header) {
      var filenameRegex = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(header);
      if (matches != null && matches[1]) {
        // fileName = matches[1].replace(/['"UTF\-8]/g, "");
        fileName = matches[1].replace(/UTF-8['"]*/g, '');
        fileName = decodeURI(fileName);

        var idOperacion = 0;
        try {
          const tempSolicitud = await MasterManager.GetMnSolicitud({ idenT_SOLICITUD: solicitud?.idSolicitud });
          idOperacion = tempSolicitud.data.content[0].idenT_OPERACION;
        } catch {
          toast.error(appConfigKey.keyMsjSolicitudOperacionUIDError, {
            duration: appConfigKey.keyDurationToast,
          });
          return;
        }

        const tipoDocumentoId = 65;
        const fileNames = `${data.genericA_DESCM_CAMPO}_${solicitudId}`;
        const archivo = await fileToBase64(response?.data);
        const fechaActual = obtenerFechaActual(0);
        const fechaVencimiento = obtenerFechaActual(1);
        const estadoAdjunto = 98;
        const documentoId = 65;
        await asociarDocumentoDigital(
          documentoId,
          tipoDocumentoId,
          fileNames,
          archivo,
          fechaActual,
          fechaVencimiento,
          estadoAdjunto,
          data,
          idOperacion
        );
      }
    }

    if (!fileName) {
      fileName = `${data.genericA_DESCM_CAMPO}_${solicitudId}.pdf`;
    }

    const url = window.URL.createObjectURL(response?.data);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName!;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const cargarListaDestinoCredito = async () => {
    var body = {
      v_LIST_IDENT_TABLA: appConfigKey.keyIdResumenDestinoCredito,
    };
    let listResultado: any = [];
    var response: any = await MasterManager.GetMnTablaGenericaDefinicion(body)
      .then((response: any) => {
        response.data.content.forEach((element: any) => {
          listResultado.push({
            id: element.genericA_VALO_CAMPO,
            label: element.genericA_DESCM_CAMPO,
          });
        });
        setDestinoCredito(listResultado);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const saveNuevoResultadoEvaluacion = async () => {
    //const validarNewReqResultadoEvaluacion = !ValidacionResultadosEvaluacionHelper.NewReqResultadoEvaluacion(reqResultadoEvaluacion);
    // if (validarNewReqResultadoEvaluacion) {
    //   return;
    // }
    // setLoadingResulEva(true);
    // await MasterManager.GetMnResultadoEvaluacionInsert(reqResultadoEvaluacion)
    //   .then(async (result: any) => {
    //     setLoadingResulEva(false);
    //     if (!result.data?.isValid) {
    //       toast.error(appConfigKey.keyMsjErrorEnServicio, { duration: appConfigKey.keyDurationToast });
    //       return;
    //     }
    //     toast.success(appConfigKey.keyMsjGuardadoExitoso, { duration: appConfigKey.keyDurationToast });
    //     setReqResultadoEvaluacion({ ...reqResultadoEvaluacion, idenT_RESULTADO: 0, idenT_DESTINO_CREDITO: 0, comentario: "" });
    //     handleNewResultadoHistorico();
    //     if (reqResultadoEvaluacion.idenT_RESULTADO === 78) {
    //       if (solicitud.idPersonaCompraPara != null && solicitud.idPersonaCompraPara !== 0) {
    //         //Reporte contrato Persona Compra Para : 77
    //         await generarDescargaContrato(solicitud.idSolicitud, appSecurityConfiguration.SessionUserId, 77, false, "");
    //       }
    //       //Soltero
    //       else if (solicitud.idEstadoCivilTitular === 1) {
    //         //Reporte contrato Soltero : 74
    //         await generarDescargaContrato(solicitud.idSolicitud, appSecurityConfiguration.SessionUserId, 74, false, "");
    //       }
    //       else if (solicitud.idEstadoCivilTitular === 2) {
    //         //Reporte contrato Casado : 75
    //         await generarDescargaContrato(solicitud.idSolicitud, appSecurityConfiguration.SessionUserId, 75, false, "");
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     setLoadingResulEva(false);
    //     if (error.response?.data) {
    //       let listExceptions: any = error.response.data.exceptions;
    //       if (listExceptions?.length > 0) {
    //         toast.error(appConfigKey.keyMsjErrorEnResultado, { duration: appConfigKey.keyDurationToast });
    //       } else {
    //         toast.error(appConfigKey.keyMsjErrorEnServicio, { duration: appConfigKey.keyDurationToast });
    //       }
    //     } else {
    //       toast.error(appConfigKey.keyMsjErrorEnServicio, { duration: appConfigKey.keyDurationToast });
    //     }
    //   });
  };

  const ObtenerResultadoEvaluacion = () => {
    MasterManager.GetMnResultadoEvaluacion(Number(solicitudId))
      .then((response: any) => {
        if (response.status !== 200) {
          toast.error(
            "Ocurrió un error con el servicio con el resultado evaluación."
          );
          return;
        }

        if (!response.data.isValid) {
          toast.error(
            "Ocurrió un error con el servicio con el resultado evaluación."
          );
          return;
        }

        if (response.data.content.length > 0) {
          setResultadoEvaluacionActual(response.data.content[0]);
        }

      })
      .catch((error) => {
        toast.error(
          "Ocurrió un error con el servicio con el resultado evaluación."
        );
      });
  };

  useEffect(() => {
    if (resultadoEvaluacionActual !== null && resultado.length > 0) {

      const { idEstadoSolicitud } = solicitud;

      if (idEstadoSolicitud !== appConfigKey.keyIdEstadoSolicitudEnEvaluacionAutonomia) {
        if (resultadoEvaluacionActual.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ||
          resultadoEvaluacionActual.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobadoConDocumentos
        ) {
          setOpcionesTipoDeResultado(listaPrimerNivelTipoResultadoAprobado);
        }

        if (resultadoEvaluacionActual.idenT_RESULTADO === appConfigKey.KeyIdResultadoObservado) {
          setOpcionesTipoDeResultado(listaPrimerNivelTipoResultadoObservado);
        }

        if (resultadoEvaluacionActual.idenT_RESULTADO === appConfigKey.KeyIdResultadorRechazado) {
          setOpcionesTipoDeResultado(listaPrimerNivelTipoResultadoRechazado);
        }

        let idenT_RESULTADO = 0;
        let idenT_TIPO_RESULTADO = 0;

        const tempResultadoFind = resultado.find(x => x.id === resultadoEvaluacionActual.idenT_RESULTADO);

        if (tempResultadoFind !== undefined) {
          idenT_RESULTADO = resultadoEvaluacionActual.idenT_RESULTADO;
          idenT_TIPO_RESULTADO = resultadoEvaluacionActual.idenT_TIPO_RESULTADO
        }

        const bodyNuevosDatos = {
          ...reqResultadoEvaluacion,
          idenT_RESULTADO: idenT_RESULTADO,
          idenT_TIPO_RESULTADO: idenT_TIPO_RESULTADO,
          comentario: resultadoEvaluacionActual?.comentario,
          idenT_DESTINO_CREDITO: resultadoEvaluacionActual.idenT_DESTINO_CREDITO,
          idenT_CREDITO_TIPO: resultadoEvaluacionActual?.idenT_TIPO_CREDITO,
          idenT_SUBTIPO: resultadoEvaluacionActual?.idenT_SUBTIPO_CREDITO,
          descc_DETALLE: resultadoEvaluacionActual?.detalle,
          numerO_VISOR: resultadoEvaluacionActual?.numerO_VISOR,
        };

        setReqResultadoEvaluacion(bodyNuevosDatos);
      }

    }
  }, [resultadoEvaluacionActual, resultado]);

  const InitDefaultResultadoEvaluacion = async () => {
    await Promise.all([
      cargarListaResultado(),
      cargarListaDestinoCredito(),
      ObtenerResultadoEvaluacion()
    ])
  }

  useEffect(() => {
    setEsModoLecturaResulEva(!esModoLectura ? false : esModoLectura);
    InitDefaultResultadoEvaluacion();
  }, []);

  return (
    <>
      {loadingResulEva && <LoadingProgress />}
      <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-4 textLabelHeadboard">
            <span className="fw-bold">RESULTADOS EVALUACIÓN</span>
          </div>
          <div className="col-lg-4">
            <span className="fw-bold"> </span>
          </div>
          <div className="col-lg-4 textAlignRigth"></div>
        </div>

        <div className="row g-4 mb-2 align-items-center">
          <div className="col-lg-6 cardObjets">
            <FormControl>
              <FormLabel>
                Decisión <span style={{ color: "red" }}> * </span>
              </FormLabel>
              <Select
                name="resultado"
                className={"select-input-card"}
                disabled={esModoLecturaResulEva}
                onChange={(event: any, newValue: any) => {

                  const { value } = event.target;

                  setReqResultadoEvaluacion({
                    ...reqResultadoEvaluacion,
                    idenT_RESULTADO: value,
                    idenT_TIPO_RESULTADO: 0,
                  });

                  if (value === appConfigKey.KeyIdResultadoAprobado ||
                    value === appConfigKey.KeyIdResultadoAprobadoConDocumentos
                  ) {

                    setOpcionesTipoDeResultado(listaPrimerNivelTipoResultadoAprobado);

                    const { idcategoriaLaboral } = titular.ingresos;

                    if (idcategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralUno ||
                      idcategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCuarta ||
                      idcategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco ||
                      idcategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCincoJubilado) {

                      const tempResultado = listaCreditoTipos.find((x) => x.value === keyCreditosConsumoNoRevolvente);

                      setReqResultadoEvaluacion({
                        ...reqResultadoEvaluacion,
                        idenT_CREDITO_TIPO: keyCreditosConsumoNoRevolvente,
                        idenT_SUBTIPO: keyDeudorMinorista,
                        descc_DETALLE: tempResultado?.detalle,
                        idenT_RESULTADO: value,
                      });

                    }
                  }

                  if (value === appConfigKey.KeyIdResultadoObservado) {
                    setOpcionesTipoDeResultado(listaPrimerNivelTipoResultadoObservado);
                  }

                  if (value === appConfigKey.KeyIdResultadorRechazado) {
                    setOpcionesTipoDeResultado(listaPrimerNivelTipoResultadoRechazado);
                  }
                  if (value === 0) {
                    setOpcionesTipoDeResultado([]);
                  }


                }}
                value={reqResultadoEvaluacion.idenT_RESULTADO}
                IconComponent={(props) => (
                  <KeyboardArrowDown
                    {...props}
                    className={`material-icons ${props.className}`}
                  />
                )}
              >
                <MenuItem value={0}>Seleccione</MenuItem>
                {resultado.map((data: any, index: any) => (
                  <MenuItem value={data.id} key={index}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 cardObjets">
            <FormControl>
              <FormLabel>
                Tipo de Decisión <span style={{ color: "red" }}> * </span>
              </FormLabel>
              <Select
                name="tipo_resultado"
                className={"select-input-card"}
                disabled={esModoLecturaResulEva}
                onChange={(event: any, newValue: any) => {
                  setReqResultadoEvaluacion({
                    ...reqResultadoEvaluacion,
                    idenT_TIPO_RESULTADO: event.target.value,
                  });
                }}
                value={reqResultadoEvaluacion.idenT_TIPO_RESULTADO}
                IconComponent={(props) => (
                  <KeyboardArrowDown
                    {...props}
                    className={`material-icons ${props.className}`}
                  />
                )}
              >
                <MenuItem value={0}>Seleccione</MenuItem>
                {opcionesTipoDeResultado.map((data: any) => (
                  <MenuItem value={data.value} key={data.key}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 cardObjets">
            <FormControl>
              <FormLabel>
                Destino crédito <span style={{ color: "red" }}> * </span>
              </FormLabel>
              <Select
                name="destinoCredito"
                className={"select-input-card"}
                disabled={esModoLecturaResulEva}
                onChange={(event: any, newValue: any) => {
                  setReqResultadoEvaluacion({
                    ...reqResultadoEvaluacion,
                    idenT_DESTINO_CREDITO: event.target.value,
                  });
                }}
                value={reqResultadoEvaluacion.idenT_DESTINO_CREDITO}
                IconComponent={(props) => (
                  <KeyboardArrowDown
                    {...props}
                    className={`material-icons ${props.className}`}
                  />
                )}
              >
                <MenuItem value={0}>Seleccione</MenuItem>
                {destinoCredito.map((data: any, index: any) => (
                  <MenuItem value={data.id} key={index}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 cardObjets">
            <FormControl>
              <FormLabel>
                Número Visor
              </FormLabel>
              <Input
                name="numeroVisor"
                placeholder="Ej: 00000000"
                disabled={esModoLecturaResulEva}
                onChange={(event: any) => {
                  const newValue = event.target.value;
                  const validation = /^\d*$/.test(newValue);
                  if (!validation) {
                    event.preventDefault();
                    return;
                  }
                  setReqResultadoEvaluacion({
                    ...reqResultadoEvaluacion,
                    numerO_VISOR: newValue,
                  });

                }}
                value={!reqResultadoEvaluacion?.numerO_VISOR ? "" : reqResultadoEvaluacion?.numerO_VISOR}
              />
            </FormControl>
          </div>
        </div>

        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-12 cardObjets">
            <FormControl>
              <FormLabel>
                Comentario <span style={{ color: "red" }}> * </span>
              </FormLabel>
              <Textarea
                name="comentario"
                placeholder="Ingresar texto"
                disabled={esModoLecturaResulEva}
                value={!reqResultadoEvaluacion?.comentario ? "" : reqResultadoEvaluacion?.comentario}
                minRows={5}
                onChange={(event: any) => {
                  setReqResultadoEvaluacion({
                    ...reqResultadoEvaluacion,
                    comentario: event.target.value,
                  });
                }}
              />
            </FormControl>
          </div>
        </div>

        {reqResultadoEvaluacion.idenT_RESULTADO ===
          appConfigKey.KeyIdResultadoAprobado && (
            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-6 cardObjets">
                <FormControl>
                  <FormLabel>
                    Tipo de crédito <span style={{ color: "red" }}> * </span>
                  </FormLabel>
                  <Select
                    name="subtipo_credito"
                    className={"select-input-card"}
                    disabled={esModoLecturaResulEva}
                    onChange={(event: any, newValue: any) => {
                      const tempResultado = listaCreditoTipos.find(
                        (item: any) => item.value === event.target.value
                      );

                      setReqResultadoEvaluacion({
                        ...reqResultadoEvaluacion,
                        idenT_CREDITO_TIPO: event.target.value,
                        descc_DETALLE: tempResultado?.detalle,
                      });
                    }}
                    value={reqResultadoEvaluacion.idenT_CREDITO_TIPO}
                    IconComponent={(props) => (
                      <KeyboardArrowDown
                        {...props}
                        className={`material-icons ${props.className}`}
                      />
                    )}
                  >
                    <MenuItem value={0}>Seleccione</MenuItem>
                    {listaCreditoTipos.map((data: any) => (
                      <MenuItem value={data.value} key={data.key}>
                        {data.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 cardObjets">
                <FormControl>
                  <FormLabel>
                    Subtipo de crédito <span style={{ color: "red" }}> * </span>
                  </FormLabel>
                  <Select
                    name="subtipo_credito"
                    className={"select-input-card"}
                    disabled={esModoLecturaResulEva}
                    onChange={(event: any, newValue: any) => {
                      setReqResultadoEvaluacion({
                        ...reqResultadoEvaluacion,
                        idenT_SUBTIPO: event.target.value,
                      });
                    }}
                    value={reqResultadoEvaluacion.idenT_SUBTIPO}
                    IconComponent={(props) => (
                      <KeyboardArrowDown
                        {...props}
                        className={`material-icons ${props.className}`}
                      />
                    )}
                  >
                    <MenuItem value={0}>Seleccione</MenuItem>
                    {listaCreditoSubtipos.map((data: any) => (
                      <MenuItem value={data.value} key={data.key}>
                        {data.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-12 cardObjets">
                <FormControl>
                  <FormLabel>
                    Detalle <span style={{ color: "red" }}> * </span>
                  </FormLabel>
                  <Textarea
                    name="detalle"
                    placeholder="Ingresar un detalle"
                    disabled={true}
                    value={reqResultadoEvaluacion.descc_DETALLE}
                    minRows={5}
                    onChange={(event: any) => {
                      setReqResultadoEvaluacion({
                        ...reqResultadoEvaluacion,
                        descc_DETALLE: event.target.value,
                      });
                    }}
                  />
                </FormControl>
              </div>
            </div>
          )}

        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-6 cardObjets"></div>
          <div className="col-lg-6 cardObjets bottom-right">
            {/* <Button
              size="md"
              color="danger"
              endDecorator={<KeyboardArrowRight />}
              disabled={esModoLecturaResulEva}
              sx={{ borderRadius: 24, width: 3 / 10 }}
              onClick={(e) => saveNuevoResultadoEvaluacion()}
            >
              Guardar
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultadosEvaluacion;
