import * as React from "react";
import IconAproved from "../../../../assets/media/icons/ico-aproved.svg";
import icoDenided from '../../../../assets/media/icons/ico-denided.svg';
import DocumentationTable from "../13.10.-base/components/DocumentationTable";
import { Sheet } from "@mui/joy";
import { appConfigDocumentoFechaVencimientos, appConfigKey, appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import MasterManager from "../../../services/origination/wapimaster.service";
import CircularProgress from '@mui/joy/CircularProgress';
import { Toaster, toast } from "sonner";
import DocumentationAddFile from "../13.10.-base/components/DocumentationAddFile";
import DocumentationDialogAdd from "../13.10.-base/components/DocumentationDialogAdd";
import DocumentationAddDescription from "../13.10.-base/components/DocumentationAddDescription";
import DownloadHelper from "../../../helpers/DownloadHelper";
import GeneralHelper from "../../../helpers/GeneralHelper";

const tableHeaders = [
  "GENERACIÓN",
  "ESTADO",
  "ARCHIVO",
  /*'COMENTARIOS',*/
  "ACCIÓN",
];

function addStyleStatus(status: number) {
  let style = '';
  switch (status) {
    case appConfigKey.keyIdEstadoDocumentoAdjuntado:
      style = 'color-aproved';
      break;
    case appConfigKey.keyIdEstadoDocumentoConforme:
      style = 'color-aproved';
      break;
    case appConfigKey.keyIdEstadoDocumentoObservado:
      style = 'color-denided';
      break;
    default: style = '';
      break;
  }
  return style;
}

function renderStatus(status: number) {
  let position = 0;
  const listStatus = [appConfigKey.keyDescripEstadoDocumentoPorAdjuntar,
  (<> {appConfigKey.keyDescripEstadoDocumentoAdjuntado} <img src={IconAproved} /></>),
  (<> {appConfigKey.keyDescripEstadoDocumentoConforme} <img src={IconAproved} /></>),
  (<> {appConfigKey.keyDescripEstadoDocumentoObservado} <img src={icoDenided} /></>)];

  switch (status) {
    case appConfigKey.keyIdEstadoDocumentoPorAdjuntar:
      position = 0;
      break;
    case appConfigKey.keyIdEstadoDocumentoAdjuntado:
      position = 1;
      break;
    case appConfigKey.keyIdEstadoDocumentoConforme:
      position = 2;
      break;
    case appConfigKey.keyIdEstadoDocumentoObservado:
      position = 3;
      break;
  }

  return listStatus[position];
}

function addStyleFile(nameFile: string) {
  return nameFile !== '-' ? 'color-file-link' : '';
}

function fileToBase64(file: any) {
  return new Promise<string>((resolve, reject) => {
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String: string = reader.result.split(',')[1];
      resolve(base64String);
    };
    reader.onerror = (error: any) => reject(error);
  });
}

function obtenerFechaActual(addYear: number) {
  const fecha = new Date();
  const anio = fecha.getFullYear();
  const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // El mes es zero-based, por lo que se suma 1
  const dia = String(fecha.getDate()).padStart(2, '0');
  const fechaActual = `${anio + addYear}-${mes}-${dia}`;
  return fechaActual;
}

const ResultadosDocumentos = (props: any) => {

  const { solicitud, esModoLectura, setDocumentos } = props;

  const [primerCheckList, setPrimerCheckList] = React.useState<any>([]);
  const [openDialogDNI, setOpenDIalogDNI] = React.useState(false);
  const [totalDocumentos, setTotalDocumentos] = React.useState<any>([]);
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [esModoLecturaDocEva, setEsModoLecturaDocEva] = React.useState(false);

  const [openDialogAddDescription, setOpenDialogAddDescription] = React.useState(false);
  const [selectedList2, setSelectedList2] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState<string | null>(null);

  const widthColumns = (index: number) => {
    const width = ["40%", "15%", "25%", "10%", "10%"];
    return width[index];
  };

  //JRM
  const handleDialogAddDescriptionOpen = (list: any[]) => {
    setSelectedList2(list);
    console.log(list);
    setOpenDialogAddDescription(true);
  }
  const handleDialogAddDescriptionClose = () => {
      setOpenDialogAddDescription(false);
      setSearchText(null);
  }
  const handleAgregarTxt = async (description: string) => {
      const index = selectedList2.findIndex((x: any) => x.codigo === 39);
      const newItems = [...selectedList2];
      var dscp = description === null ? "" : " - " + description;
      newItems[index].descripcion = "Miscelaneos" + dscp;
      setOpenDialogAddDescription(false);
      await handleChangeSwitch(selectedList2);
      setSelectedList2([]);
  }
  //JRM

  const obtenerDocumentos = async () => {
    setShow(false)
    setLoading(true)
    MasterManager.GetMnDocumentacionDocumento({
      "idenT_DOCUMENTACION_DOCUMENTO": 0,
      "idenT_SOLICITUD": solicitud?.idSolicitud
    }).then(response => {
      const { content } = response.data;

      const checklist1 = content.filter((x: any) => x.descC_TIPO_LISTADO == "CHECKLIST1");
      let updateCheckList1: any = checklist1.map((x: any) => {
        return {
          code: x.idenT_DOCUMENTACION_DOCUMENTO,
          documentType: x.idenT_TIPO_DOCUMENTO,
          document: x.descC_TIPO_DOCUMENTO,
          status: x.idenT_ESTADO_ADJUNTO,
          file: x.idenT_ESTADO_ADJUNTO !== appConfigKey.keyIdEstadoDocumentoPorAdjuntar ? `${x.descC_NOMBRE_ARCHIVO}` : '-',
          fulldata: x,
          disabledFileUploader: esModoLectura || x.idenT_ESTADO_ADJUNTO === appConfigKey.keyIdEstadoDocumentoConforme
        }
      });

      setPrimerCheckList(updateCheckList1);
      setDocumentos(updateCheckList1)
      obtenerTodosDocumentosPorTipo(updateCheckList1)
      setShow(true);
    })
      .catch(e => setLoading(false))
      .finally(() => setLoading(false));

  };

  const obtenerTodosDocumentosPorTipo = (updateCheckList: any) => {
    ServicesProvider.ObtenerDocumentosPorTipo({
      "filtro": "T",
      "estado": 0
    }).then((response: any) => {
      const finalTotal: any = [];
      const content = response.data.content;
      if (content?.sBTTipoDocumentoDigital) {
        const tipoDocumentoDigital = content.sBTTipoDocumentoDigital;
        const listDocumentosDigitales = tipoDocumentoDigital.sBTTipoDocumentoDigital;


        listDocumentosDigitales.map((item: any) => {
          const finder = updateCheckList.find((x: any) => x.fulldata.idenT_TIPO_DOCUMENTO === item.codigo);

          // if (finder === undefined) {
          //   finalTotal.push(item);
          // }

          finalTotal.push(item);
        });
      }
      setTotalDocumentos(finalTotal);
    });
  };

  const asociarDocumentoDigital = async (documentoId: any, tipoDocumentoId: number, nombre: any, archivoCodificado: any, fechaEmision: any, fechaVencimiento: any, estadoAdjunto: number, fulldata: any, operacionId: number) => {
    const responseAsociarDocumentoDigital = estadoAdjunto === appConfigKey.keyIdEstadoDocumentoPorAdjuntar ?
      await ServicesProvider.AsociarDocumentoDigital({ tipoDocumentoId, nombre, archivoCodificado, fechaEmision, fechaVencimiento }, operacionId) :
      await ServicesProvider.ActualizarDocumentoDigital({ documentoId, nombre, archivoCodificado, fechaEmision, fechaVencimiento }, operacionId);

    const msgError = GeneralHelper.ValidarCargaDocumento(responseAsociarDocumentoDigital);
    if (msgError) {
      toast.error(msgError, {
        duration: appConfigKey.keyDurationToast,
      });
      return;
    }

    const idenT_DOCUMENTO_BT = estadoAdjunto === appConfigKey.keyIdEstadoDocumentoPorAdjuntar ?
      responseAsociarDocumentoDigital?.data?.content?.documentoId :
      fulldata.idenT_DOCUMENTO_BT;

    if (!idenT_DOCUMENTO_BT || idenT_DOCUMENTO_BT === 0) {
      toast.error(appConfigKey.keyMsjCargaDocumentoIdError, {
        duration: appConfigKey.keyDurationToast,
      });
      return;
    }

    const body = {
      "idenT_DOCUMENTACION_DOCUMENTO": fulldata.idenT_DOCUMENTACION_DOCUMENTO,
      "idenT_SOLICITUD": fulldata.idenT_SOLICITUD,
      "idenT_TIPO_LISTADO": fulldata.idenT_TIPO_LISTADO,
      "descC_TIPO_LISTADO": fulldata.descC_TIPO_LISTADO,
      "idenT_TIPO_DOCUMENTO": fulldata.idenT_TIPO_DOCUMENTO,
      "descC_TIPO_DOCUMENTO": fulldata.descC_TIPO_DOCUMENTO,
      "idenT_ESTADO_ADJUNTO": appConfigKey.keyIdEstadoDocumentoAdjuntado,
      "descC_ESTADO_ADJUNTO": appConfigKey.keyDescripEstadoDocumentoAdjuntado,
      "descC_NOMBRE_ARCHIVO": nombre.split(".")[0],
      "descC_EXTENSION_ARCHIVO": `.${nombre.split(".")[1]}`,
      "idenT_DOCUMENTO_BT": idenT_DOCUMENTO_BT,
      "idenT_USUARIO": appSecurityConfiguration.SessionUserId
    };

    await MasterManager.GetMnDocumentacionDocumentoUpdate(body);

    obtenerDocumentos();
  };

  const eliminarDocumentoOrigination = async (data: any) => {
    let status = false;

    const bodyMaster = {
      "idenT_DOCUMENTACION_DOCUMENTO": data.fulldata.idenT_DOCUMENTACION_DOCUMENTO
    };
    let result = await MasterManager.GetMnDocumentacionDocumentoDeleteById(bodyMaster);

    if (result?.status !== 200) {
      return status;
    }

    if (!result?.data.isValid) {
      return status;
    }

    return true;
  }

  const eliminarDocumentoBantotal = async (data: any) => {
    let status = false;

    let result = await ServicesProvider.EliminarDocumentoDigital(solicitud?.idOperacion, data?.fulldata?.idenT_DOCUMENTO_BT);

    if (result?.status !== 200) {
      return status;
    }

    if (!result?.data.isValid) {
      return status;
    }

    return true;
  }

  const handleDelete = async (data: any) => {
    try {
      if (data?.fulldata?.idenT_ESTADO_ADJUNTO === appConfigKey.keyIdEstadoDocumentoPorAdjuntar) {

        let statusOrigination = await eliminarDocumentoOrigination(data);

        if (!statusOrigination) {
          toast.error(`Falló la eliminación del documento "${data?.document}"`, { duration: appConfigKey.keyDurationToast });
          return;
        }

      } else {

        if (!solicitud?.idOperacion) {
          toast.error('La solicitud no se encuentra en Bantotal', { duration: appConfigKey.keyDurationToast });
          return;
        }

        if (!data?.fulldata?.idenT_DOCUMENTO_BT) {
          toast.error(`El documento "${data?.document}" no se encuentra en Bantotal`, { duration: appConfigKey.keyDurationToast });
          return;
        }

        let statusBanTotal = await eliminarDocumentoBantotal(data);

        if (!statusBanTotal) {
          toast.error(`Falló la eliminación del documento "${data?.document}" en Bantotal`, { duration: appConfigKey.keyDurationToast });
          return;
        }

        let statusOrigination = await eliminarDocumentoOrigination(data);

        if (!statusOrigination) {
          toast.error(`Falló la eliminación del documento "${data?.document}"`, { duration: appConfigKey.keyDurationToast });
          return;
        }

      }

      toast.success(`Se eliminó correctamente el documento "${data?.document}"`, { duration: appConfigKey.keyDurationToast });

      obtenerDocumentos();
    } catch (error) {

    }
  }

  const handleFile = async (fileUploaded: any, data: any) => {

    const tipoDocumentoId = Number(data.fulldata.idenT_TIPO_DOCUMENTO);
    const fileName = fileUploaded.name;
    const archivo = await fileToBase64(fileUploaded);
    const fechaActual = obtenerFechaActual(0);
    const fechaVencimiento = appConfigDocumentoFechaVencimientos.includes(tipoDocumentoId) ? obtenerFechaActual(1) : appDefaultValues.StringEmpty;
    const estadoAdjunto = data.fulldata.idenT_ESTADO_ADJUNTO;
    const documentoId = estadoAdjunto === appConfigKey.keyIdEstadoDocumentoPorAdjuntar ? data.fulldata.idenT_TIPO_DOCUMENTO : data.fulldata.idenT_DOCUMENTO_BT

    var idOperacion = 0;
    try {
      const tempSolicitud = await MasterManager.GetMnSolicitud({ idenT_SOLICITUD: solicitud?.idSolicitud });
      idOperacion = tempSolicitud.data.content[0].idenT_OPERACION;
    } catch {
      toast.error(appConfigKey.keyMsjSolicitudOperacionUIDError, {
        duration: appConfigKey.keyDurationToast,
      });
      return;
    }

    await asociarDocumentoDigital(documentoId, tipoDocumentoId, fileName, archivo, fechaActual, fechaVencimiento, estadoAdjunto, data.fulldata, idOperacion);
  };

  const handleDialogDNIOpen = () => {
    setOpenDIalogDNI(true);
  };

  const handleAddFile = (event: any) => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleChangeSwitch = async (selecteds: any) => {
    for (var i = 0; i < selecteds.length; i++) {

      const body = {
        "idenT_DOCUMENTACION_DOCUMENTO": 0,
        "idenT_SOLICITUD": solicitud?.idSolicitud,
        "idenT_TIPO_LISTADO": 1,
        "descC_TIPO_LISTADO": "CHECKLIST1",
        "idenT_TIPO_DOCUMENTO": selecteds[i].codigo,
        "descC_TIPO_DOCUMENTO": selecteds[i].descripcion,
        "idenT_ESTADO_ADJUNTO": appConfigKey.keyIdEstadoDocumentoPorAdjuntar,
        "descC_ESTADO_ADJUNTO": appConfigKey.keyDescripEstadoDocumentoPorAdjuntar,
        "descC_NOMBRE_ARCHIVO": null,
        "descC_EXTENSION_ARCHIVO": null,
        "idenT_DOCUMENTO_BT": 0,
        "idenT_USUARIO": appSecurityConfiguration.SessionUserId
      };

      await MasterManager.GetMnDocumentacionDocumentoInsert(body);
    }

    handleDialogClose();
    obtenerDocumentos();
  };

  const handleDownloadFile = async (event: any, data: any) => {
    event.preventDefault();
    const response: any = await descargarDocumentoAdjunto(data.fulldata.idenT_DOCUMENTO_BT, solicitud?.idOperacion);

    if (!response) {
      toast.error(`Error al descargar el documento "${data.file}"`, {
        duration: appConfigKey.keyDurationToast,
      });
      return;
    }

    const fileData = response?.data.content.sdtDocumentosDigitales.documento[0];
    const file = DownloadHelper.convertBase64ToFile(fileData.archivo, fileData.nombre);
    const a = document.createElement("a");
    const url = window.URL.createObjectURL(file);
    a.href = url;
    a.download = fileData.nombre;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const descargarDocumentoAdjunto = async (documentoId: number, operacionId: number) => {
    try {
      const responseObtenerDocumentoDigital = await ServicesProvider.ObtenerDocumentoDigital({
        "documentoId": documentoId
      }, operacionId);

      if (responseObtenerDocumentoDigital.status !== 200) {
        return;
      }

      if (!responseObtenerDocumentoDigital.data.isValid) {
        return;
      }

      return responseObtenerDocumentoDigital;
    } catch (error) {
      return null;
    }
  };


  React.useEffect(() => {
    setEsModoLecturaDocEva(!esModoLectura ? false : esModoLectura);
    obtenerDocumentos();
  }, []);



  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-4 textLabelHeadboard">
          <span className="fw-bold">DOCUMENTOS</span>
        </div>
        <div className="col-lg-4">
          <span className="fw-bold"> </span>
        </div>
        <div className="col-lg-4 textAlignRigth"></div>
      </div>

      <div className="row g-2 mb-2 align-items-center">
        {isLoading &&
          <div className="col text-center">
            <CircularProgress size="md" variant='plain' color="danger" />
          </div>
        }
        {show &&
          <Sheet sx={{ overflow: 'auto' }}>
            <DocumentationTable
              widthColumns={widthColumns}
              tableHeaders={tableHeaders}
              tableData={primerCheckList}
              addStyleStatus={addStyleStatus}
              renderStatus={renderStatus}
              addStyleFile={addStyleFile}
              handlerFile={handleFile}
              handlerDelete={handleDelete}
              handleDialogDNIOpen={handleDialogDNIOpen}
              handleDownloadFile={handleDownloadFile}
            />
            <div className="col pt-3 pb-1 text-end">
              <DocumentationAddFile disabled={esModoLecturaDocEva} handlerOnClick={handleAddFile} />
            </div>
          </Sheet>
        }
        {!isLoading && !show && <label>No hay registros.</label>}
        <DocumentationDialogAdd
          open={open}
          handleClose={handleDialogClose}
          tableData={totalDocumentos}
          handleChangeSwitch={handleChangeSwitch}
          handleOpenSecondModal={handleDialogAddDescriptionOpen}
        />
        <DocumentationAddDescription
            open={openDialogAddDescription}
            handleClose={handleDialogAddDescriptionClose}
            handleAgregar={handleAgregarTxt}
        />
      </div>
    </div>
  );
};

export default ResultadosDocumentos;
