import { appConfigKey, appConfigProducto } from "../../config/Config";

const ParameterHelper = {

    GetDocumentTypeName: (documentId: number): string => {
        let documentTypeName = ""
        switch (documentId) {
            case appConfigKey.keyIdDNI: {
                documentTypeName = "DNI"
                break;
            }
            case appConfigKey.keyIdRUC: {
                documentTypeName = "RUC"
                break;
            }
        }
        return documentTypeName;
    },

    GetSolicitudeIdentifier: (solicitudeId: any): string => {
        return "S" + `${solicitudeId}`.padStart(9, '0');
    },

    GetQuotationIdentifier: (quotationId: any): string => {
        return "C" + `${quotationId}`.padStart(9, '0');
    },

    /**
     * Lista de productos que calculan la cuota balloon
     * (100 - PorcentajeCuotaInicial)
     * @returns 
     */
    GetCalProductBalloon: (): number[] => {
        return [
            appConfigProducto.Plan5012M_Sol,
            appConfigProducto.Plan5024M_Sol,
            appConfigProducto.Plan50Paperless12M_Sol,
            appConfigProducto.Plan50Paperless24M_Sol,
            // appConfigProducto.CompraMaestra_Sol,
            // appConfigProducto.CompraMaestraPaperless_Sol,
            appConfigProducto.CuotaFlex_Sol,
            appConfigProducto.CuotaFlexPaperless_Sol,

            appConfigProducto.Plan5012M_Dolar,
            appConfigProducto.Plan5024M_Dolar,
            appConfigProducto.Plan50Paperless12M_Dolar,
            appConfigProducto.Plan50Paperless24M_Dolar,
            // appConfigProducto.CompraMaestra_Dolar,
            // appConfigProducto.CompraMaestraPaperless_Dolar,
            appConfigProducto.CuotaFlex_Dolar,
            appConfigProducto.CuotaFlexPaperless_Dolar,
        ];
    },

    /**
     * Lista de todos los productos que aceptan cuota balloon, 
     * ingresando manualmente o calculado (100 - PorcentajeCuotaInicial)
     * @returns 
     */
    GetAllProductBalloon: (): number[] => {
        return [
            ...ParameterHelper.GetCalProductBalloon(),
            appConfigProducto.CompraMaestra_Sol,
            appConfigProducto.CompraMaestraPaperless_Sol,
            appConfigProducto.CompraMaestra_Dolar,
            appConfigProducto.CompraMaestraPaperless_Dolar,
        ];
    }
}

export default ParameterHelper;