import * as React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { Select, MenuItem } from '@mui/material';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { appConfigKey, appSecurityConfiguration } from "../../../../config/Config";
import MasterManager from "../../../services/origination/wapimaster.service";
import { Listado } from "../../../models/ManagerProfile/ResultadoEvaluacion";
import { Input, Textarea } from "@mui/joy";
import ReportManager from "../../../services/support/wapireport";
import LoadingProgress from "../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import { General } from "../7.10.-base/obtenerServicios";
import { toast } from "sonner";
import GeneralHelper from "../../../helpers/GeneralHelper";

const ResultadosEvaluacion = ({ handleNewResultadoHistorico, solicitud, esModoLectura, reqResultadoEvaluacion, setReqResultadoEvaluacion }: any) => {
  const { solicitudId } = useParams();
  const [resultado, setResultado] = React.useState<Listado[]>([])
  const [tipoResultado, setTipoResultado] = React.useState<Listado[]>([])
  const [destinoCredito, setDestinoCredito] = React.useState<Listado[]>([])
  const [tipoCreditos, setTipoCreditos] = React.useState<Listado[]>([])
  const [subtipoCreditos, setSubtipoCreditos] = React.useState<Listado[]>([])
  const [loadingResulEva, setLoadingResulEva] = React.useState(false);
  const [esModoLecturaResulEva, setEsModoLecturaResulEva] = React.useState(false);

  const cargarResultados = async () => {
    let response: any = await General.ObtenerResultados();
    if (response.length > 0) {
      let listDecision: any = response.filter((x: any) => x.id !== appConfigKey.KeyIdResultadoAprobadoConDocumentos);
      setResultado(listDecision);
    }
  }

  const cargarTipoResultados = async (tablaId: string) => {
    let response: any = await General.ObtenerTipoResultados(tablaId);
    if (response.length > 0) {
      setTipoResultado(response);
    }
  }

  const descargarReporte = async (solicitudId: number, usuarioId: number, reporteId: number, bpo: boolean) => {
    try {
      const responseObtenerReporte = await ReportManager.ObtenerReporte({
        "idSolicitud": solicitudId,
        "idUsuario": usuarioId,
        "idReporte": reporteId,
        "indBPO": bpo
      });

      if (responseObtenerReporte.status === 200) {
        return responseObtenerReporte;
      }
    } catch (error) {
      //myowasp('Ocurrió un error al asociar el documento');
      return null;
    }
  };

  const asociarDocumentoDigital = async (documentoId: any, tipoDocumentoId: number, nombre: any, archivoCodificado: any, fechaEmision: any, fechaVencimiento: any, estadoAdjunto: number, fulldata: any, operacionId: number) => {
    var responseAsociarDocumentoDigital = await ServicesProvider.AsociarDocumentoDigital({ tipoDocumentoId, nombre, archivoCodificado, fechaEmision, fechaVencimiento }, operacionId);

    const msgError = GeneralHelper.ValidarCargaDocumento(responseAsociarDocumentoDigital, true);
    if (msgError) {
      toast.error(msgError, {
        duration: appConfigKey.keyDurationToast,
      });
      return;
    }

    const body = {
      "idenT_DOCUMENTACION_DOCUMENTO": 0,
      "idenT_SOLICITUD": solicitudId,
      "idenT_TIPO_LISTADO": 2,
      "descC_TIPO_LISTADO": "CHECKLIST2",
      "idenT_TIPO_DOCUMENTO": 65,
      "descC_TIPO_DOCUMENTO": "Carta Confirmación",
      "idenT_ESTADO_ADJUNTO": appConfigKey.keyIdEstadoDocumentoAdjuntado,
      "descC_ESTADO_ADJUNTO": appConfigKey.keyDescripEstadoDocumentoAdjuntado,
      "descC_NOMBRE_ARCHIVO": nombre,
      "descC_EXTENSION_ARCHIVO": ".pdf",
      "idenT_DOCUMENTO_BT": responseAsociarDocumentoDigital?.data?.content?.documentoId,
      "idenT_USUARIO": appSecurityConfiguration.SessionUserId
    };
    await MasterManager.GetMnDocumentacionDocumentoInsert(body);
  }

  const generarDescargaContrato = async (solicitudId: number, usuarioId: number, reporteId: number, bpo: boolean, data: any) => {
    const response: any = await descargarReporte(solicitudId, usuarioId, reporteId, bpo);
    if (!response) {
      //myowasp('Error al descargar el documento.');
      return;
    }

    function obtenerFechaActual(addYear: number) {
      const fecha = new Date();
      const anio = fecha.getFullYear();
      const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // El mes es zero-based, por lo que se suma 1
      const dia = String(fecha.getDate()).padStart(2, '0');
      const fechaActual = `${anio + addYear}-${mes}-${dia}`;
      return fechaActual;
    }

    function fileToBase64(file: any) {
      return new Promise<string>((resolve, reject) => {
        const reader: any = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64String: string = reader.result.split(',')[1];
          resolve(base64String);
        };
        reader.onerror = (error: any) => reject(error);
      });
    }

    var fileName = null;
    var header = response?.headers['content-disposition'];
    if (header) {
      var filenameRegex = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(header);
      if (matches != null && matches[1]) {
        //fileName = matches[1].replace(/['"UTF\-8]/g, '');
        fileName = matches[1].replace(/UTF-8['"]*/g, '');
        fileName = decodeURI(fileName);

        const tipoDocumentoId = 65;
        const fileNames = `${data.genericA_DESCM_CAMPO}_${solicitudId}`;
        const archivo = await fileToBase64(response?.data);
        const fechaActual = obtenerFechaActual(0);
        const fechaVencimiento = obtenerFechaActual(1);
        const estadoAdjunto = 98;
        const documentoId = 65;

        var idOperacion = 0;
        try {
          const tempSolicitud = await MasterManager.GetMnSolicitud({ idenT_SOLICITUD: solicitudId });
          idOperacion = tempSolicitud.data.content[0].idenT_OPERACION;
        } catch {
          toast.error(appConfigKey.keyMsjSolicitudOperacionUIDError, {
            duration: appConfigKey.keyDurationToast,
          });
          return;
        }
        
        await asociarDocumentoDigital(documentoId, tipoDocumentoId, fileNames, archivo, fechaActual, fechaVencimiento, estadoAdjunto, data, idOperacion);
      }
    }

    if (!fileName) {
      fileName = `${data.genericA_DESCM_CAMPO}_${solicitudId}.pdf`;
    }

    const url = window.URL.createObjectURL(response?.data);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName!;
    a.click();
    window.URL.revokeObjectURL(url);
  }


  const cargarDestinoCreditos = async () => {
    let response: any = await General.ObtenerDestinoCredito();
    if (response.length > 0) {
      setDestinoCredito(response);
    }
  }


  const cargarTipoCreditos = async () => {
    let response: any = await General.ObtenerTipoCreditos();
    if (response.length > 0) {
      setTipoCreditos(response);
    }
  }

  const cargarSubtipoCreditos = async () => {
    let response: any = await General.ObtenerSubtipoCredito();
    if (response.length > 0) {
      setSubtipoCreditos(response);
    }

  }

  const cargarResultadoAnterior = async () => {
    let response: any = await General.ObtenerResultadoEvaluacion(Number(solicitudId));
    if (response.length > 0) {
      let esEtapas = response[0].etapA_RESULTADO === appConfigKey.KeyValidacionDocumental ||
        response[0].etapA_RESULTADO === appConfigKey.KeyEtapaCrediticia ||
        response[0].etapA_RESULTADO === appConfigKey.KeyEtapaCrediticiaExcepcion ||
        response[0].etapA_RESULTADO === appConfigKey.KeyEtapaCrediticiaAutonimia;
      if (esEtapas) {
        if (response[0].etapA_RESULTADO === appConfigKey.KeyValidacionDocumental) {
          setReqResultadoEvaluacion({
            ...reqResultadoEvaluacion,
            idenT_RESULTADO: response[0].idenT_RESULTADO,
            idenT_TIPO_RESULTADO: response[0].idenT_TIPO_RESULTADO,
            idenT_CREDITO_TIPO: Number(response[0].idenT_TIPO_CREDITO),
            descc_DETALLE: response[0].detalle,
            idenT_SUBTIPO: Number(response[0].idenT_SUBTIPO_CREDITO),
            comentario: response[0].comentario,
            idenT_DESTINO_CREDITO: response[0].idenT_DESTINO_CREDITO,
            numerO_VISOR: response[0].numerO_VISOR,
          })
        }
        if (response[0].etapA_RESULTADO === appConfigKey.KeyEtapaCrediticia ||
          response[0].etapA_RESULTADO === appConfigKey.KeyEtapaCrediticiaExcepcion ||
          response[0].etapA_RESULTADO === appConfigKey.KeyEtapaCrediticiaAutonimia) {
          setReqResultadoEvaluacion({
            ...reqResultadoEvaluacion,
            idenT_RESULTADO: response[0].idenT_RESULTADO,
            idenT_CREDITO_TIPO: Number(response[0].idenT_TIPO_CREDITO),
            descc_DETALLE: response[0].detalle,
            idenT_SUBTIPO: Number(response[0].idenT_SUBTIPO_CREDITO),
            idenT_DESTINO_CREDITO: response[0].idenT_DESTINO_CREDITO
          })
        }
        if (response[0].idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado) {
          await cargarTipoResultados(appConfigKey.KeyCodigoTipoResultadosAprobado);
        }

        if (response[0].idenT_RESULTADO === appConfigKey.KeyIdResultadoObservado) {
          await cargarTipoResultados(appConfigKey.KeyCodigoTipoResultadosObservado);
        }

        if (response[0].idenT_RESULTADO === appConfigKey.KeyIdResultadorRechazado) {
          await cargarTipoResultados(appConfigKey.KeyCodigoTipoResultadosRechazado);
        }

      } else {
        setReqResultadoEvaluacion({
          ...reqResultadoEvaluacion,
          idenT_CREDITO_TIPO: appConfigKey.keyCodigoCreditoConsumoNoResolvente,
          descc_DETALLE: appConfigKey.keyDetalleCreditoConsumoNoResolvente,
          idenT_SUBTIPO: appConfigKey.keyCodigoDeudorMinorista,
        })
      }
    }

  }
  const InitDefaultCargarTipoResultado = async () => {

    if (reqResultadoEvaluacion?.idenT_RESULTADO) {
      if (reqResultadoEvaluacion?.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado) {
        await cargarTipoResultados(appConfigKey.KeyCodigoTipoResultadosAprobado);
      }

      if (reqResultadoEvaluacion?.idenT_RESULTADO === appConfigKey.KeyIdResultadoObservado) {
        await cargarTipoResultados(appConfigKey.KeyCodigoTipoResultadosObservado);
      }

      if (reqResultadoEvaluacion?.idenT_RESULTADO === appConfigKey.KeyIdResultadorRechazado) {
        await cargarTipoResultados(appConfigKey.KeyCodigoTipoResultadosRechazado);
      }
    }
  }


  const InitDefaultResultEvaluacion = async () => {
    setEsModoLecturaResulEva(!esModoLectura ? false : esModoLectura);
    await Promise.all([
      cargarResultados(),
      InitDefaultCargarTipoResultado(),
      cargarDestinoCreditos(),
      cargarTipoCreditos(),
      cargarSubtipoCreditos(),
      cargarResultadoAnterior()
    ])
  }


  useEffect(() => {
    InitDefaultResultEvaluacion();
  }, [])

  return (
    <>
      {loadingResulEva && <LoadingProgress />}
      <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">

        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-4 textLabelHeadboard">
            <span className="fw-bold">RESULTADOS EVALUACIÓN</span>
          </div>
          <div className="col-lg-4">
            <span className="fw-bold"> </span>
          </div>
          <div className="col-lg-4 textAlignRigth"></div>
        </div>

        <div className="row g-4 mb-2 align-items-center">
          <div className="col-lg-6 cardObjets">
            <FormControl>
              <FormLabel>Decisión <span style={{ color: "red" }}> * </span></FormLabel>
              <Select
                name="resultado"
                className={"select-input-card"}
                disabled={esModoLecturaResulEva}
                onChange={(event: any, newValue: any) => {
                  setReqResultadoEvaluacion({
                    ...reqResultadoEvaluacion,
                    idenT_RESULTADO: event.target.value,
                    idenT_TIPO_RESULTADO: 0,
                  })
                  setTipoResultado([]);
                  if (Number(event.target.value) === appConfigKey.KeyIdResultadoAprobado) {
                    cargarTipoResultados(appConfigKey.KeyCodigoTipoResultadosAprobado);
                  }

                  if (Number(event.target.value) === appConfigKey.KeyIdResultadoObservado) {
                    cargarTipoResultados(appConfigKey.KeyCodigoTipoResultadosObservado);
                  }

                  if (Number(event.target.value) === appConfigKey.KeyIdResultadorRechazado) {
                    cargarTipoResultados(appConfigKey.KeyCodigoTipoResultadosRechazado);
                  }

                }}
                value={reqResultadoEvaluacion.idenT_RESULTADO}
                IconComponent={props => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}
              >
                <MenuItem value={0}>Seleccione</MenuItem>
                {resultado.map((data: any, index: any) => (
                  <MenuItem value={data.id} key={index}>{data.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 cardObjets">
            <FormControl>
              <FormLabel>
                Tipo de Decisión <span style={{ color: "red" }}> * </span>
              </FormLabel>
              <Select
                name="tipo_resultado"
                className={"select-input-card"}
                disabled={esModoLecturaResulEva}
                onChange={(event: any, newValue: any) => {
                  setReqResultadoEvaluacion({
                    ...reqResultadoEvaluacion,
                    idenT_TIPO_RESULTADO: event.target.value,
                  });
                }}
                value={reqResultadoEvaluacion.idenT_TIPO_RESULTADO}
                IconComponent={(props) => (
                  <KeyboardArrowDown
                    {...props}
                    className={`material-icons ${props.className}`}
                  />
                )}
              >
                <MenuItem value={0}>Seleccione</MenuItem>
                {tipoResultado.map((data: any) => (
                  <MenuItem value={data.id} key={data.id}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 cardObjets">
            <FormControl>
              <FormLabel>Destino crédito <span style={{ color: "red" }}> * </span></FormLabel>
              <Select
                name="destinoCredito"
                className={"select-input-card"}
                disabled={esModoLecturaResulEva}
                onChange={(event: any, newValue: any) => {
                  setReqResultadoEvaluacion({ ...reqResultadoEvaluacion, idenT_DESTINO_CREDITO: event.target.value })
                }}
                value={reqResultadoEvaluacion.idenT_DESTINO_CREDITO}
                IconComponent={props => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}
              >
                <MenuItem value={0}>Seleccione</MenuItem>
                {destinoCredito.map((data: any, index: any) => (
                  <MenuItem value={data.id} key={index}>{data.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 cardObjets">
            <FormControl>
              <FormLabel>
                Número Visor <span style={{ color: "red" }}> * </span>
              </FormLabel>
              <Input
                name="numeroVisor"
                placeholder="Ej: 00000000"
                value={!reqResultadoEvaluacion?.numerO_VISOR ? "" : reqResultadoEvaluacion?.numerO_VISOR}
                disabled={esModoLecturaResulEva}
                onChange={(event: any) => {
                  const newValue = event.target.value;
                  const validation = /^\d*$/.test(newValue);
                  if (!validation) {
                    event.preventDefault();
                    return;
                  }
                  setReqResultadoEvaluacion({
                    ...reqResultadoEvaluacion,
                    numerO_VISOR: event.target.value,
                  });

                }}
              />
            </FormControl>
          </div>
        </div>

        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-12 cardObjets">
            <FormControl>
              <FormLabel>
                Comentario <span style={{ color: "red" }}> * </span>
              </FormLabel>
              <Textarea
                name="comentario"
                placeholder="Ingresar texto"
                disabled={esModoLecturaResulEva}
                minRows={5}
                value={reqResultadoEvaluacion.comentario}
                onChange={(event: any) => {
                  setReqResultadoEvaluacion({ ...reqResultadoEvaluacion, comentario: event.target.value })
                }}
              />
            </FormControl>
          </div>
        </div>
        {reqResultadoEvaluacion.idenT_RESULTADO ===
          appConfigKey.KeyIdResultadoAprobado && (
            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-6 cardObjets">
                <FormControl>
                  <FormLabel>
                    Tipo de crédito <span style={{ color: "red" }}> * </span>
                  </FormLabel>
                  <Select
                    name="subtipo_credito"
                    className={"select-input-card"}
                    disabled={esModoLecturaResulEva}
                    onChange={(event: any, newValue: any) => {
                      const tempResultado = tipoCreditos.find(
                        (item: any) => item.id === event.target.value
                      );
                      setReqResultadoEvaluacion({
                        ...reqResultadoEvaluacion,
                        idenT_CREDITO_TIPO: event.target.value,
                        descc_DETALLE: !tempResultado?.detail ? '' : tempResultado?.detail,
                      });
                    }}
                    value={reqResultadoEvaluacion.idenT_CREDITO_TIPO}
                    IconComponent={(props) => (
                      <KeyboardArrowDown
                        {...props}
                        className={`material-icons ${props.className}`}
                      />
                    )}
                  >
                    <MenuItem value={0}>Seleccione</MenuItem>
                    {tipoCreditos.map((data: any) => (
                      <MenuItem value={data.id} key={data.id}>
                        {data.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-6 cardObjets">
                <FormControl>
                  <FormLabel>
                    Subtipo de crédito <span style={{ color: "red" }}> * </span>
                  </FormLabel>
                  <Select
                    name="subtipo_credito"
                    className={"select-input-card"}
                    disabled={esModoLecturaResulEva}
                    onChange={(event: any, newValue: any) => {
                      setReqResultadoEvaluacion({
                        ...reqResultadoEvaluacion,
                        idenT_SUBTIPO: event.target.value,
                      });
                    }}
                    value={reqResultadoEvaluacion.idenT_SUBTIPO}
                    IconComponent={(props) => (
                      <KeyboardArrowDown
                        {...props}
                        className={`material-icons ${props.className}`}
                      />
                    )}
                  >
                    <MenuItem value={0}>Seleccione</MenuItem>
                    {subtipoCreditos.map((data: any) => (
                      <MenuItem value={data.id} key={data.id}>
                        {data.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-12 cardObjets">
                <FormControl>
                  <FormLabel>
                    Detalle <span style={{ color: "red" }}> * </span>
                  </FormLabel>
                  <Textarea
                    name="detalle"
                    placeholder="Ingresar un detalle"
                    disabled={true}
                    value={reqResultadoEvaluacion.descc_DETALLE}
                    minRows={5}
                    onChange={(event: any) => {
                      setReqResultadoEvaluacion({
                        ...reqResultadoEvaluacion,
                        descc_DETALLE: event.target.value,
                      });
                    }}
                  />
                </FormControl>
              </div>
            </div>
          )}

        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-6 cardObjets"></div>
          <div className="col-lg-6 cardObjets bottom-right">
            {/* <Button
              size="md"
              color="danger"
              endDecorator={<KeyboardArrowRight />}
              disabled={esModoLecturaResulEva}
              sx={{ borderRadius: 24, width: 3 / 10 }}
              onClick={(e) => saveNuevoResultadoEvaluacion()}
            >
              Guardar
            </Button> */}
          </div>
        </div>

      </div>
    </>
  );
};

export default ResultadosEvaluacion;
