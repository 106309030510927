import MainLayout from '../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout';
import { Tab, Tabs } from '@mui/material';
//import Input from '@mui/joy/Input';
import './documentation.scss';

import IconAproved from '../../../../assets/media/icons/ico-aproved.svg';
import icoDenided from '../../../../assets/media/icons/ico-denided.svg';

import DocumentationTable from './components/DocumentationTable';
import DocumentationAddFile from './components/DocumentationAddFile';
import DocumentationBtnBack from './components/DocumentationBtnBack';
import DocumentationSendValidation from './components/DocumentationSendValidation';
import DocumentationNote from './components/DocumentationNote';
import DocumentationDialogAdd from './components/DocumentationDialogAdd';
import DocumentationDialogDNI from './components/DocumentationDialogDNI';
import DocumentationAddDescription from './components/DocumentationAddDescription';
//import DocumentationVerificationTable from './components/DocumentationVerificationTable';
import DocumentationContratosTable from './components/DocumentationContratosTable';
import DocumentationCheckList2Table from './components/DocumentationCheckList2Table';
import DocumentationDownload from './components/DocumentationDownload';
import DocumentationAlert from './components/DocumentationAlert';
//import DocumentationPlacaValidation from './components/DocumentationPlacaValidation';
import DocumentationTabPanel from './components/DocumentationTabPanel';
import DocumentationCardTitle from './components/DocumentationCardTitle';
import MasterManager from "../../../services/origination/wapimaster.service";
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import { useNavigate } from "react-router-dom";
import ReportManager from '../../../services/support/wapireport';
import { appConfigDecisionMotor, appConfigDocumentoFechaVencimientos, appConfigEstadoSolicitud, appConfigEstadosProcesoEtapaAnalisisCredito, appConfigEtapaAnalisisCredito, appConfigKey, appConfigPerfiles, appConfigProcesoEtapaAnalisisCredito, appConfigTipoTransaccionLog, appDefaultValues, appSecurityConfiguration } from '../../../../config/Config';
import LoadingProgress from '../../1.-shared/1.4.-loading/loading-progress/loading-progress';
import JsonHelper from '../../../helpers/JsonHelper';
import RequestManager from '../../../services/origination/wapirequest.service';
import { Toaster, toast } from "sonner";
import DownloadHelper from '../../../helpers/DownloadHelper';
import { MnIntegracionSolicitudRequest } from '../../../models/RequestManager/integracion-solicitud-request.model';
import { ExceptionResponse } from '../../../models/Shared/result-response.model';
import DatosAdicionalesModal from '../../3.-solicitude/3.10.-base/components/DatosAdicionalesModal';
import { useEffect, useState } from 'react';
import { General } from '../../3.-solicitude/3.10.-base/obtenerServicios';
import RequestExceptionDialog from './components/RequestExceptionDialog';
import DocumentationRechazado from './components/DocumentationRechazado';
import ValidacionRequestExceptionHelper from './helpers/ValidacionRequestExceptionHelper';
import React from 'react';
import { ObtenerListas } from './obtenerServicios';
import GeneralHelper from '../../../helpers/GeneralHelper';

//const headerTitles = ['REQUERIDOS', 'VERIF. EQUIFAX', 'CONTRATOS', 'PLACAS'];
const headerTitles = ['REQUERIDOS', 'CONTRATOS'];
const START_POSITION = 0;
const tableHeaders = ['GENERACIÓN', 'ESTADO', 'ARCHIVO' /*, 'COMENTARIOS' */, 'ACCIÓN'];
//const tableVerificationHeaders = ['DOCUMENTOS DESCARGABLES', 'EMISION', 'RESPUESTA', 'ACCIÓN'];
const tableContratosHeaders = ['DOCUMENTOS DESCARGABLES', 'ACCIÓN'];
const tableChecklist2Headers = ['BPO', 'ESTADO', 'ARCHIVO',/* 'COMENTARIOS' */, 'ACCIÓN'];

function createData(code: any, document: any, status: any, file: any) {
    return { code, document, status, file };
}

function createDataFull(code: any, documentType: any, document: any, status: any, file: any, fulldata: any, disabledFileUploader: any) {
    return { code, documentType, document, status, file, fulldata, disabledFileUploader };
}

function renderStatus(status: number) {
    let position = 0;
    const listStatus = [appConfigKey.keyDescripEstadoDocumentoPorAdjuntar,
    (<> {appConfigKey.keyDescripEstadoDocumentoAdjuntado} <img src={IconAproved} /></>),
    (<> {appConfigKey.keyDescripEstadoDocumentoConforme} <img src={IconAproved} /></>),
    (<> {appConfigKey.keyDescripEstadoDocumentoObservado} <img src={icoDenided} /></>)];

    switch (status) {
        case appConfigKey.keyIdEstadoDocumentoPorAdjuntar:
            position = 0;
            break;
        case appConfigKey.keyIdEstadoDocumentoAdjuntado:
            position = 1;
            break;
        case appConfigKey.keyIdEstadoDocumentoConforme:
            position = 2;
            break;
        case appConfigKey.keyIdEstadoDocumentoObservado:
            position = 3;
            break;
    }

    return listStatus[position];
}

const tableRequeridosData = [
    createData(1, 'Boleta de paga Titular', 0, '-'),
    createData(2, 'DNI Titular', 0, '-'),
    createData(3, 'Boleta de pago Cónyuge', 1, 'boleta.doc'),
    createData(4, 'DNI Cónyuge', 0, '-'),
    createData(5, 'Acta de matrimonio', 0, '-')
];

const tableRequeridosCheckList2Data = [
    createData(1, 'Carta de confirmación / Características', 0, '-'),
    createData(2, 'Copia comprobante cuota inicial', 0, '-'),
    createData(3, 'Cotización vehículo', 0, '-'),
    createData(4, 'Pagaré', 0, '-'),
    createData(5, 'Hoja de resumen', 0, '-'),
    createData(6, 'Cronograma', 0, '-'),
    createData(7, 'Consulta telefónica domicilio', 0, '-'),
    createData(8, 'Dow Jones de todos los intervinientes', 0, '-'),
    createData(9, 'Validación PLAFT', 0, '-'),
    createData(10, 'Validación de multa electoral', 0, '-'),
    createData(11, 'Formato GPS', 0, '-'),
    createData(12, 'Seguro desgravamen', 0, '-'),
    createData(13, 'Carta de aprobación', 0, '-'),
    createData(14, 'Declaración de uso del vehículo', 0, '-'),
    createData(15, 'Protección de datos personales', 0, '-'),
    createData(17, 'Carta beneficio Repsol', 0, '-'),
    createData(18, 'Póliza de seguro', 0, '-'),
    createData(19, 'Fedateo', 0, '-'),
    createData(20, 'Verificación domiciliaria', 0, '-'),
    createData(21, 'Verificación laboral', 0, '-'),
    createData(22, 'Validación de vigencia de poderes', 0, '-'),
];

/*
function createDataVerification(document: string, emision: string, response: string) {
    return { document, emision, response };
}
*/

/*
const tableVerificationData = [
    createDataVerification('Verificacion', '10 Mar 2023', '12 Mar 2023')
];
*/

function addStyleFile(nameFile: string) {
    return nameFile !== '-' ? 'color-file-link' : '';
}

function addStyleStatus(status: number) {
    let style = '';
    switch (status) {
        case appConfigKey.keyIdEstadoDocumentoAdjuntado:
            style = 'color-aproved';
            break;
        case appConfigKey.keyIdEstadoDocumentoConforme:
            style = 'color-aproved';
            break;
        case appConfigKey.keyIdEstadoDocumentoObservado:
            style = 'color-denided';
            break;
        default: style = '';
            break;
    }
    return style;
}

function fileToBase64(file: any) {
    return new Promise<string>((resolve, reject) => {
        const reader: any = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String: string = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error: any) => reject(error);
    });
}

function obtenerFechaActual(addYear: number) {
    const fecha = new Date();
    const anio = fecha.getFullYear();
    const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // El mes es zero-based, por lo que se suma 1
    const dia = String(fecha.getDate()).padStart(2, '0');
    const fechaActual = `${anio + addYear}-${mes}-${dia}`;
    return fechaActual;
}

export default function Documentation() {

    let navigate = useNavigate();

    let urlParams = new URLSearchParams(window.location.search);

    const IdSolicitud = urlParams.get('Solicitud');

    const [primerCheckList, setPrimerCheckList] = useState<any>([]);
    const [segundoCheckList, setSegundoCheckList] = useState<any>([]);
    const [solicitud, setSolicitud] = useState<any>();
    const [totalDocumentos, setTotalDocumentos] = useState<any>([]);
    const [totalDocumentosListUno, setTotalDocumentosListUno] = useState<any>([]);
    const [solicitudEtapas, setSolicitudEtapas] = useState<any>([]);
    const [contratoList, setContratoList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDisableBtnRed, setIsDisableBtnRed] = useState<boolean>(false);
    const [isDisabledFileUploader1, setIsDisabledFileUploader1] = useState<boolean>(false);
    const [isDisabledFileUploader2, setIsDisabledFileUploader2] = useState<boolean>(false);

    //Modal
    const [openDatosAdicionales, setOpenDatosAdicionales] = useState(false);
    const [isDisableBtnRevision, setIsDisableBtnRevision] = useState(false);
    const [dataAdicionalSolicitud, setDataAdicionalSolicitud] = useState<any>(null);
    const [idPerfilT, setIdPerfilT] = useState<any>(0);
    const [openRequestExceptionDialog, setOpenRequestExceptionDialog] = useState(false);
    const [codigoDecisionMotor, setCodigoDecisionMotor] = useState<any>('');
    const [mensajeDecisionMotor, setMensajeDecisionMotor] = useState<any>(null);

    const [isVisibleBtnEnviarEvaluacion, setIsVisibleBtnEnviarEvaluacion] = useState(false);
    const [isVisibleBtnSolicitarExcepcion, setIsVisibleBtnSolicitarExcepcion] = useState(false);
    const [isVisibleBtnEnviarValidacion, setIsVisibleBtnEnviarValidacion] = useState(false);
    const [isVisibleBtnEnviarRevision, setIsVisibleBtnEnviarRevision] = useState(false);


    const [isDisableBtnEnviarEvaluacion, setIsDisableBtnEnviarEvaluacion] = useState(false);
    const [isDisableBtnSolicitarExcepcion, setIsDisableBtnSolicitarExcepcion] = useState(false);
    const [isDisableBtnEnviarValidacion, setIsDisableBtnEnviarValidacion] = useState(false);
    const [isDisableBtnEnviarRevision, setIsDisableBtnEnviarRevision] = useState(false);

    const [esFlujoTercera, setEsFlujoTercera] = useState<boolean>(false);
    const [esJuridico, setEsJuridico] = useState<boolean>(false);
    const [esNaturalConNegocio, setEsNaturalConNegocio] = useState<boolean>(false);

    const handleCloseOpenDatosAdicionales = () => {
        setOpenDatosAdicionales(false);
    };
    const setBtnModal = () => {
        setIsDisableBtnRevision(true);
    }

    const handleShowOpenDatosAdicionales = async () => {
        cargarDataDatosAdicionales();
        setOpenDatosAdicionales(true);
    };

    const asociarDocumentoDigital = async (documentoId: any, tipoDocumentoId: number, nombre: any, archivoCodificado: any, fechaVencimiento: any, fechaEmision: any, estadoAdjunto: number, fulldata: any) => {
        var idOperacion = 0;
        try {
            const tempSolicitud = await MasterManager.GetMnSolicitud({ idenT_SOLICITUD: IdSolicitud });
            idOperacion = tempSolicitud.data.content[0].idenT_OPERACION;
        } catch {
            toast.error(appConfigKey.keyMsjSolicitudOperacionUIDError, {
                duration: appConfigKey.keyDurationToast,
            });
            return;
        }

        const responseAsociarDocumentoDigital = estadoAdjunto === appConfigKey.keyIdEstadoDocumentoPorAdjuntar ?
            await ServicesProvider.AsociarDocumentoDigital({ tipoDocumentoId, nombre, archivoCodificado, fechaVencimiento, fechaEmision }, idOperacion) :
            await ServicesProvider.ActualizarDocumentoDigital({ documentoId, nombre, archivoCodificado, fechaVencimiento, fechaEmision }, idOperacion);

        const msgError = GeneralHelper.ValidarCargaDocumento(responseAsociarDocumentoDigital);
        if (msgError) {
            toast.error(msgError, {
                duration: appConfigKey.keyDurationToast,
            });
            return;
        }

        const idenT_DOCUMENTO_BT = estadoAdjunto === appConfigKey.keyIdEstadoDocumentoPorAdjuntar ?
            responseAsociarDocumentoDigital.data.content.documentoId :
            fulldata.idenT_DOCUMENTO_BT;

        if (!idenT_DOCUMENTO_BT || idenT_DOCUMENTO_BT === 0) {
            toast.error(appConfigKey.keyMsjCargaDocumentoIdError, {
                duration: appConfigKey.keyDurationToast,
            });
            return;
        }

        const body = {
            "idenT_DOCUMENTACION_DOCUMENTO": fulldata.idenT_DOCUMENTACION_DOCUMENTO,
            "idenT_SOLICITUD": fulldata.idenT_SOLICITUD,
            "idenT_TIPO_LISTADO": fulldata.idenT_TIPO_LISTADO,
            "descC_TIPO_LISTADO": fulldata.descC_TIPO_LISTADO,
            "idenT_TIPO_DOCUMENTO": fulldata.idenT_TIPO_DOCUMENTO,
            "descC_TIPO_DOCUMENTO": fulldata.descC_TIPO_DOCUMENTO,
            "idenT_ESTADO_ADJUNTO": appConfigKey.keyIdEstadoDocumentoAdjuntado,
            "descC_ESTADO_ADJUNTO": appConfigKey.keyDescripEstadoDocumentoAdjuntado,
            "descC_NOMBRE_ARCHIVO": nombre.split(".")[0],
            "descC_EXTENSION_ARCHIVO": `.${nombre.split(".")[1]}`,
            "idenT_DOCUMENTO_BT": idenT_DOCUMENTO_BT,
            "idenT_USUARIO": appSecurityConfiguration.SessionUserId
        };

        await MasterManager.GetMnDocumentacionDocumentoUpdate(body);
        obtenerDocumentos();
    };

    const generarCheckList = (checklist: []) => {

        var nuevoCheckList: any = [];

        checklist.forEach((x: any) => {
            nuevoCheckList.push(
                createDataFull(
                    x.idenT_DOCUMENTACION_DOCUMENTO,
                    x.idenT_TIPO_DOCUMENTO,
                    x.descC_TIPO_DOCUMENTO,
                    x.idenT_ESTADO_ADJUNTO,
                    x.idenT_ESTADO_ADJUNTO !== appConfigKey.keyIdEstadoDocumentoPorAdjuntar ? `${x.descC_NOMBRE_ARCHIVO}` : '-',
                    x,
                    x.idenT_ESTADO_ADJUNTO === appConfigKey.keyIdEstadoDocumentoConforme
                )
            );
        });

        return nuevoCheckList;
    }

    const obtenerDocumentos = async () => {

        const requestBody = {
            "idenT_DOCUMENTACION_DOCUMENTO": 0,
            "idenT_SOLICITUD": IdSolicitud
        };

        const response = await MasterManager.GetMnDocumentacionDocumento(requestBody);
        const { content } = response.data;

        const checklist1 = content.filter((x: any) => x.descC_TIPO_LISTADO == "CHECKLIST1");
        const checklist2 = content.filter((x: any) => x.descC_TIPO_LISTADO == "CHECKLIST2");

        var updateCheckList1 = generarCheckList(checklist1);
        var updateCheckList2 = generarCheckList(checklist2);

        setPrimerCheckList(updateCheckList1);
        setSegundoCheckList(updateCheckList2);

        if (updateCheckList1.length > 0) {
            let cantDocumento1 = updateCheckList1.length;
            let cantDocumentoConformes1 = updateCheckList1.filter((x: any) => x.fulldata.idenT_ESTADO_ADJUNTO == appConfigKey.keyIdEstadoDocumentoConforme).length;
            if (cantDocumento1 === cantDocumentoConformes1) {
                setCheckList(1);
            } else {
                setCheckList(0);
            }
        } else {
            setCheckList(0);
        }

        await obtenerSolicitud(updateCheckList2, updateCheckList1);


    };

    const obtenerContratos = async () => {

        const request = {
            "v_LIST_IDENT_TABLA": appConfigKey.keyIdReporte
        };

        await MasterManager.GetMnTablaGenericaDefinicion(request).then(response => {

            const { content } = response.data;

            let contratoList = content.filter((x: any) => x.genericA_CODL_CAMPO == "1" && x.genericA_IDENT_CAMPO != 51);

            contratoList = contratoList.sort((a: any, b: any) => a.genericA_CODI_ORDEN - b.genericA_CODI_ORDEN);

            setContratoList(contratoList);

        });

    };

    const obtenerTodosDocumentosPorTipo = async (updateCheckList2: any, updateCheckList1: any, estado: number) => {
        await ServicesProvider.ObtenerDocumentosPorTipo({ "filtro": "T", "estado": 0 }).then((response: any) => {

            const finalTotalListUno: any = [];

            const content = response.data.content;
            if (content?.sBTTipoDocumentoDigital) {

                const tipoDocumentoDigital = content.sBTTipoDocumentoDigital;
                const listUnoDocumentosDigitales = tipoDocumentoDigital.sBTTipoDocumentoDigital;

                listUnoDocumentosDigitales.map((item: any) => {
                    /*const finder = updateCheckList1.find((x: any) => x.fulldata.idenT_TIPO_DOCUMENTO === item.codigo);

                    if (finder === undefined) {*/
                    finalTotalListUno.push(item);
                    /*}*/
                });

            }

            if (finalTotalListUno.length > 0) {
                finalTotalListUno.sort((a: any, b: any) => a.descripcion.localeCompare(b.descripcion));
            }

            setTotalDocumentosListUno(finalTotalListUno);

        });

        await ServicesProvider.ObtenerDocumentosPorTipo({ "filtro": "R", "estado": estado }).then((response: any) => {
            const finalTotalListDos: any = [];

            const content = response.data.content;
            if (content?.sBTTipoDocumentoDigital) {

                const tipoDocumentoDigital = content.sBTTipoDocumentoDigital;
                const listDocumentosDigitales = tipoDocumentoDigital.sBTTipoDocumentoDigital;

                listDocumentosDigitales.map((item: any) => {
                    /*const finder = updateCheckList2.find((x: any) => x.fulldata.idenT_TIPO_DOCUMENTO === item.codigo);

                    if (finder === undefined) {*/
                    finalTotalListDos.push(item);
                    /*}*/
                });

            }

            if (finalTotalListDos.length > 0) {
                finalTotalListDos.sort((a: any, b: any) => a.descripcion.localeCompare(b.descripcion));
            }

            setTotalDocumentos(finalTotalListDos);
        });
    };

    const DocumentacionDocumentoInsert = async (body: any) => {
        await MasterManager.GetMnDocumentacionDocumentoInsert(body);
    };

    const GetMnIntegracionSolicitudInsert = async (solicitudId: number, tipoTransaccion: number, params: any, request: any, response: any) => {
        let body = {
            idenT_SOLICITUD: solicitudId,
            params: params ? JSON.stringify(params) : '{}',
            request: request ? JSON.stringify(request) : '{}',
            response: response ? JSON.stringify(response) : '{}',
            iD_TIPO_TRANSACCION: tipoTransaccion,
            idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId
        } as MnIntegracionSolicitudRequest;

        try {
            let result = await RequestManager.GetMnIntegracionSolicitudInsert(body);
            return result?.status === 200 && result?.data?.isValid;
        } catch (error) {
            return false;
        }
    }

    const BTObtenerResumenPrestamo = async (operacionUId: number) => {
        try {
            let result = await ServicesProvider.BTObtenerResumenPrestamo(operacionUId);
            if (result?.status === 200 && result?.data?.isValid && result?.data?.content) {
                return result.data.content;
            } else if (result?.data?.exceptions && result?.data?.exceptions?.length > 0) {
                return result?.data?.exceptions[0];
            } else {
                return {
                    code: "Frontend",
                    description: `Response status code does not indicate success: ${result?.status}.`
                } as ExceptionResponse
            }
        } catch (error) {
            return null;
        }
    }

    const obtenerDocumentosPorTipo = async (idenT_SOLICITUD: any, estado: number, updateCheckList2: any) => {
        try {

            const response = await ServicesProvider.ObtenerDocumentosPorTipo({
                "filtro": "R",
                "estado": estado
            });

            const content = response.data.content;
            if (content?.sBTTipoDocumentoDigital) {
                const tipoDocumentoDigital = content.sBTTipoDocumentoDigital;
                const listDocumentosDigitales = tipoDocumentoDigital.sBTTipoDocumentoDigital;
                let seInsertaraDocumento = false;

                for (const documentoDigital of listDocumentosDigitales) {
                    const finder = updateCheckList2.find((x: any) => x.fulldata.idenT_TIPO_DOCUMENTO === documentoDigital.codigo);
                    if (!finder) {
                        seInsertaraDocumento = true;
                        const body = {
                            "idenT_DOCUMENTACION_DOCUMENTO": 0,
                            "idenT_SOLICITUD": idenT_SOLICITUD,
                            "idenT_TIPO_LISTADO": 2,
                            "descC_TIPO_LISTADO": "CHECKLIST2",
                            "idenT_TIPO_DOCUMENTO": documentoDigital.codigo,
                            "descC_TIPO_DOCUMENTO": documentoDigital.descripcion,
                            "idenT_ESTADO_ADJUNTO": appConfigKey.keyIdEstadoDocumentoPorAdjuntar,
                            "descC_ESTADO_ADJUNTO": appConfigKey.keyDescripEstadoDocumentoPorAdjuntar,
                            "descC_NOMBRE_ARCHIVO": null,
                            "descC_EXTENSION_ARCHIVO": null,
                            "idenT_DOCUMENTO_BT": 0,
                            "idenT_USUARIO": appSecurityConfiguration.SessionUserId
                        };
                        await DocumentacionDocumentoInsert(body);
                    }
                }

                if (seInsertaraDocumento) {
                    obtenerDocumentos();
                }
            }

        } catch (error) {

            //myowasp("Error al obtener documentos por tipo:", error);
        }
    };

    const obtenerSolicitud = async (updateCheckList2: any, updateCheckList1: any) => {
        let idUsuarioLogeador = Number(
            localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
        )
        const response = await MasterManager.GetMnSolicitud({ idenT_SOLICITUD: IdSolicitud });
        const tempSolicitud = response.data.content[0];
        await obtenerEstadoMotorSolicitud(tempSolicitud?.iD_ESTADO_SOLICITUD);
        setSolicitud(tempSolicitud);

        if (updateCheckList2.length === 0) {
            await obtenerDocumentosPorTipo(IdSolicitud, tempSolicitud.iD_ESTADO_VEHICULO_FINANCIAR, updateCheckList2);
        }

        await obtenerTodosDocumentosPorTipo(updateCheckList2, updateCheckList1, tempSolicitud.iD_ESTADO_VEHICULO_FINANCIAR);

        if (tempSolicitud.iD_ESTADO_SOLICITUD === appConfigEstadoSolicitud.Rechazado) {
            setIsDisabledFileUploader1(true);
            setIsDisabledFileUploader2(true);
        }

        if (appSecurityConfiguration.SessionPerfil !== appConfigPerfiles.CodigoEjecutivo &&
            appSecurityConfiguration.SessionPerfil !== appConfigPerfiles.CodigoJefeComercial
        ) {
            setIsDisabledFileUploader1(true);
            setIsDisabledFileUploader2(true);
        } else {

            if (tempSolicitud.idenT_USUARIO_CREADOR === idUsuarioLogeador) {
                if (tempSolicitud.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoSolicitudPorValidar) {
                    setIsDisabledFileUploader1(true);
                }
                if (tempSolicitud.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoSolicitudPorRevisar) {
                    setIsDisabledFileUploader2(true);
                }
            } else {
                setIsDisabledFileUploader1(true);
                setIsDisabledFileUploader2(true);
            }
        }

    };

    const obtenerEmpresaPorPersona = async (personaId: any) => {
        const dataIngresos = await General.Ingresos(personaId);
        const ingresoPrincipal = dataIngresos.find((x: any) => x.inD_INGRESO_PRINCIPAL === true);
        const dataEmpresa: any = await General.Empresa(ingresoPrincipal.idenT_EMPRESA);
        return { dataEmpresa, ingresoPrincipal };
    };

    const cargarDataDatosAdicionales = async () => {
        let idPersona: any = solicitud.idenT_PERSONA_TITULAR;
        let oldDataTitular: any = await General.Persona(idPersona);
        let { dataEmpresa, ingresoPrincipal } = await obtenerEmpresaPorPersona(oldDataTitular?.idenT_PERSONA ?? 0);
        let oldDataConyuge: any = null;
        let dataEmpresaConyuge: any = null;
        let ingresoPrincipalConyuge: any = null;
        if (solicitud.idenT_PERSONA_CONYUGUE &&
            (oldDataTitular.iD_ESTADO_CIVIL === appConfigKey.keyIdEstadoCivilCasado ||
                oldDataTitular.iD_ESTADO_CIVIL === appConfigKey.keyIdEstadoCivilConviviente)) {
            oldDataConyuge = await General.Persona(solicitud.idenT_PERSONA_CONYUGUE);
            let dataLaboralConyuge = await obtenerEmpresaPorPersona(oldDataConyuge?.idenT_PERSONA ?? 0);
            dataEmpresaConyuge = dataLaboralConyuge.dataEmpresa;
            ingresoPrincipalConyuge = dataLaboralConyuge.ingresoPrincipal;
        }
        setDataAdicionalSolicitud({
            "oldDataTitular": oldDataTitular,
            "oldDataConyuge": oldDataConyuge,
            "oldDataTitularEmpresa": dataEmpresa,
            "oldDataTitularIngresoPrincipal": ingresoPrincipal,
            "oldDataConyugeEmpresa": dataEmpresaConyuge,
            "oldDataConyugeIngresoPrincipal": ingresoPrincipalConyuge,
        });

        return {
            "oldDataTitular": oldDataTitular,
            "oldDataConyuge": oldDataConyuge,
            "oldDataTitularEmpresa": dataEmpresa,
            "oldDataTitularIngresoPrincipal": ingresoPrincipal,
            "oldDataConyugeEmpresa": dataEmpresaConyuge,
            "oldDataConyugeIngresoPrincipal": ingresoPrincipalConyuge,
        }
    }

    const obtenerPerfilTracking = async () => {
        await MasterManager.GetMnSolicitudTracking({ idenT_SOLICITUD: Number(IdSolicitud) }).then((response: any) => {
            const temp = response.data.content[0].idenT_PERFIL;
            setIdPerfilT(temp);
        });
    }

    const obtenerConfigAccionesPorSolicitud = async () => {

        setIsVisibleBtnSolicitarExcepcion(false);
        setIsVisibleBtnEnviarEvaluacion(false);
        setIsVisibleBtnEnviarValidacion(false);
        setIsDisableBtnSolicitarExcepcion(false);
        setIsDisableBtnEnviarEvaluacion(false);
        setIsDisableBtnEnviarValidacion(false);

        const response = await ObtenerListas.GetSolicitudEtapas(null, Number(IdSolicitud));
        if (response.length > 0) {
            setSolicitudEtapas(response);
            let solicitudEtapasAprobadas: any = response.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado || x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion);
            if (solicitudEtapasAprobadas.length === response.length) {
                setIsVisibleBtnSolicitarExcepcion(false);
                setIsVisibleBtnEnviarEvaluacion(false);
                setIsVisibleBtnEnviarValidacion(false);
                return
            }

            let solicitudEtapasRechazada: any = response.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado);
            if (solicitudEtapasRechazada.length > 0) {
                setIsVisibleBtnSolicitarExcepcion(false);
                setIsVisibleBtnEnviarEvaluacion(false);
                setIsVisibleBtnEnviarValidacion(false);
                return
            }


            let solicitudAnteriorAprobadoConDocumentos: any = response.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion && x.ordenProceso === 1);
            let solicitudConAutonomia: any = response.filter((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarCreditoAutonomia);

            if (solicitudAnteriorAprobadoConDocumentos.length > 0 && solicitudConAutonomia.length === 0) {
                setIsVisibleBtnSolicitarExcepcion(false);
                setIsVisibleBtnEnviarEvaluacion(false);
                setIsVisibleBtnEnviarValidacion(false);
                return
            }

            let solicitudEtapaUno: any = response.find((x: any) => x.ordenProceso === 1);
            if (solicitudEtapaUno.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente ||
                solicitudEtapaUno.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Observado) {

                if (solicitudEtapaUno.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion) {
                    setIsVisibleBtnEnviarEvaluacion(true);
                }
                if (solicitudEtapaUno.identProceso === appConfigProcesoEtapaAnalisisCredito.SolicitarExcepcion) {
                    setIsVisibleBtnSolicitarExcepcion(true);
                }
                if (solicitudEtapaUno.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarValidacion) {
                    setIsVisibleBtnEnviarValidacion(true);
                }
                return
            }
            if (solicitudEtapaUno.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado) {

                if (solicitudEtapaUno.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion) {
                    setIsVisibleBtnEnviarEvaluacion(true);
                    setIsDisableBtnEnviarEvaluacion(true);
                }
                if (solicitudEtapaUno.identProceso === appConfigProcesoEtapaAnalisisCredito.SolicitarExcepcion) {
                    setIsVisibleBtnSolicitarExcepcion(true);
                    setIsDisableBtnSolicitarExcepcion(true);
                }

                if (solicitudEtapaUno.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarValidacion) {
                    setIsVisibleBtnEnviarValidacion(true);
                    setIsDisableBtnEnviarValidacion(true);
                }

                if (solicitudEtapaUno.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarCreditoAutonomia) {
                    if (solicitudEtapaUno.identDecisionMotor === appConfigDecisionMotor.ZonaGris) {
                        setIsVisibleBtnEnviarEvaluacion(true);
                        setIsDisableBtnEnviarEvaluacion(true);

                    }
                    if (solicitudEtapaUno.identDecisionMotor === appConfigDecisionMotor.Rechazado) {
                        setIsVisibleBtnSolicitarExcepcion(true);
                        setIsDisableBtnSolicitarExcepcion(true);
                    }
                }
                return
            }

            let solicitudEtapaDos: any = response.find((x: any) => x.ordenProceso === 2);
            if (solicitudEtapaDos) {
                if (solicitudEtapaDos.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente ||
                    solicitudEtapaDos.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Observado) {
                    if (solicitudEtapaDos.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarValidacion) {
                        setIsVisibleBtnEnviarValidacion(true);
                    }
                    return
                }

                if (solicitudEtapaDos.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado) {
                    if (solicitudEtapaDos.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarValidacion) {
                        setIsVisibleBtnEnviarValidacion(true);
                        setIsDisableBtnEnviarValidacion(true);
                    }
                    if (solicitudEtapaDos.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarCreditoAutonomia) {
                        if (solicitudEtapaDos.identDecisionMotor === appConfigDecisionMotor.ZonaGris) {
                            setIsVisibleBtnEnviarEvaluacion(true);
                            setIsDisableBtnEnviarEvaluacion(true);

                        }
                        if (solicitudEtapaDos.identDecisionMotor === appConfigDecisionMotor.Rechazado) {
                            setIsVisibleBtnSolicitarExcepcion(true);
                            setIsDisableBtnSolicitarExcepcion(true);
                        }
                    }
                    return
                }
            }


            let solicitudEtapaTres: any = response.find((x: any) => x.ordenProceso === 3);
            if (solicitudEtapaTres) {
                if (solicitudEtapaTres.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente ||
                    solicitudEtapaTres.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Observado) {
                    if (solicitudEtapaTres.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarValidacion) {
                        setIsVisibleBtnEnviarValidacion(true);
                    }
                    return
                }
                if (solicitudEtapaTres.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado) {
                    if (solicitudEtapaTres.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarValidacion) {
                        setIsVisibleBtnEnviarValidacion(true);
                        setIsDisableBtnEnviarValidacion(true);
                    }
                }
            }

        }

    }

    const obtenerEstadoMotorSolicitud = async (idEstadoSolicitud: number) => {

        setCodigoDecisionMotor(null);
        setMensajeDecisionMotor(null);

        if (idEstadoSolicitud !== appConfigKey.keyIdEstadoPendiente &&
            idEstadoSolicitud !== appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion &&
            idEstadoSolicitud !== appConfigKey.keyIdEstadoSolicitudPorLiberarExcepcion &&
            idEstadoSolicitud !== appConfigKey.KeyIdEstadoSolicitudObservado) {
            return;
        }

        let responseMotorBeforceStepTwo: any = null;
        const tempEvaluacionSolicitud = await GetMnEvaluacionSolicitud(IdSolicitud);
        if (tempEvaluacionSolicitud.length > 0) {
            for (let evaluacion of tempEvaluacionSolicitud) {
                if (evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepTwo) {
                    responseMotorBeforceStepTwo = evaluacion?.jsoN_RESPONSE ? JSON.parse(evaluacion.jsoN_RESPONSE) : null;
                    break;
                }
            }
        }

        if (!responseMotorBeforceStepTwo) {
            return;
        }

        let decision = responseMotorBeforceStepTwo?.response?.Politica_Creditos?.decision_Credito?.decision;
        setCodigoDecisionMotor(decision);

        if (decision === appConfigKey.CodigoEvaluacionRechazado) {
            let validacionModelicaDecision = ValidacionRequestExceptionHelper.ModelicaDecision(responseMotorBeforceStepTwo.response)

            if (validacionModelicaDecision.mensajeModelica) {
                setMensajeDecisionMotor(validacionModelicaDecision.mensajeModelica);
            }

        }
    }

    const CheckVerificacionMotor = () => {
        if (solicitud) {
            setEsFlujoTercera(solicitud?.titulaR_JURIDICO || solicitud?.titulaR_ID_CATEGORIA_LABORAL_PRINCIPAL == appConfigKey.keyCodigoCategoriaLaboralTres);
            setEsJuridico(solicitud?.titulaR_JURIDICO);
            setEsNaturalConNegocio(!solicitud?.titulaR_JURIDICO && solicitud?.titulaR_ID_CATEGORIA_LABORAL_PRINCIPAL == appConfigKey.keyCodigoCategoriaLaboralTres);
        }
    }

    const InitDefaultDocumentos = async () => {

        setIsLoading(true)
        try {
            await obtenerConfigAccionesPorSolicitud();
            await Promise.all([
                obtenerDocumentos(),
                obtenerContratos(),
                obtenerPerfilTracking()
            ]);
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        InitDefaultDocumentos();
    }, []);

    useEffect(() => {
        CheckVerificacionMotor();
    }, [solicitud]);

    const [tabsPosition, setTabsPosition] = useState(START_POSITION);

    const [open, setOpen] = React.useState(false);
    const [openDialogDocumentsCheckList2, setOpenDialogDocumentsCheckList2] = React.useState(false);
    const [openDialogDNI, setOpenDIalogDNI] = React.useState(false);
    const [openDialogAddDescription, setOpenDialogAddDescription] = React.useState(false);
    const [selectedList2, setSelectedList2] = React.useState<any>([]);
    const [searchText, setSearchText] = React.useState<string | null>(null);
    const [tipoChk, setTipoChk] = React.useState<number | null>(null);

    const [showAlert, setShowAlert] = useState(false);

    const [checklist, setCheckList] = useState(0);
    const handleDocumentationAlert = () => {
        setShowAlert(false);
    };

    const handleTabsPosition = (e: any, value: any) => {
        setTabsPosition(value);
    };

    const widthColumns = (index: number) => {
        const width = ['40%', '15%', '20%', '15%', '10%'];
        return width[index];
    };

    const contratosWidthColumns = (index: number) => {
        const width = ['80%', '20%'];
        return width[index];
    };

    //JRM
    const handleDialogAddDescriptionOpen = (list: any[]) => {
        setSelectedList2(list);
        console.log(list);
        setOpenDialogAddDescription(true);
    }
    const handleDialogAddDescriptionClose = () => {
        setOpenDialogAddDescription(false);
        setSearchText(null);
    }
    const handleAgregarTxt = async (description: string) => {
        const index = selectedList2.findIndex((x: any) => x.codigo === 39);
        const newItems = [...selectedList2];
        var dscp = description === null ? "" : " - " + description;
        newItems[index].descripcion = "Miscelaneos" + dscp;
        setOpenDialogAddDescription(false);
        if (tipoChk == 1) {
            await handleChangeSwitchListUno(selectedList2);
        } else {
            await handleChangeSwitch(selectedList2);
        }

        setSelectedList2([]);
    }
    const handleTipoCheckList1 = () => {
        setTipoChk(1);
    }
    const handleTipoCheckList2 = () => {
        setTipoChk(2);
    }
    //jrm

    const handleFile = async (fileUploaded: any, data: any) => {
        const tipoDocumentoId = Number(data.fulldata.idenT_TIPO_DOCUMENTO);
        const fileName = fileUploaded.name;
        const archivo = await fileToBase64(fileUploaded);
        let fechaEmision = obtenerFechaActual(0);
        let fechaVencimiento = appConfigDocumentoFechaVencimientos.includes(tipoDocumentoId) ? obtenerFechaActual(1) : appDefaultValues.StringEmpty;
        const estadoAdjunto = data.fulldata.idenT_ESTADO_ADJUNTO;
        const documentoId = data.fulldata.idenT_DOCUMENTO_BT;
        //const documentoId = data.fulldata.idenT_TIPO_DOCUMENTO;

        if (tipoDocumentoId === 77 || tipoDocumentoId === 144) {
            fechaVencimiento = obtenerFechaActual(1);
        }

        await asociarDocumentoDigital(documentoId, tipoDocumentoId, fileName, archivo, fechaVencimiento, fechaEmision, estadoAdjunto, data.fulldata);

    };

    const handleDownloadFile = async (event: any, data: any) => {
        event.preventDefault();
        const response: any = await descargarDocumentoAdjunto(data.fulldata.idenT_DOCUMENTO_BT);

        if (!response) {
            //myowasp('Error al descargar el documento.');
            return;
        }

        const fileData = response?.data.content.sdtDocumentosDigitales.documento[0];
        const file = DownloadHelper.convertBase64ToFile(fileData.archivo, fileData.nombre);
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(file);
        a.href = url;
        a.download = fileData.nombre;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const descargarDocumentoAdjunto = async (documentoId: number) => {
        const responseObtenerDocumentoDigital = await ServicesProvider.ObtenerDocumentoDigital({
            "documentoId": documentoId
        }, solicitud.idenT_OPERACION);

        if (responseObtenerDocumentoDigital.status !== 200) {
            //myowasp('Ocurrió un error al asociar el documento');
            return;
        }

        if (!responseObtenerDocumentoDigital.data.isValid) {
            //myowasp('Ocurrió un error en la respuesta del servicio externo.');
            return;
        }

        return responseObtenerDocumentoDigital;
    };

    const descargarReporte = async (solicitudId: number, usuarioId: number, reporteId: number, bpo: boolean) => {
        const responseObtenerReporte = await ReportManager.ObtenerReporte({
            "idSolicitud": solicitudId,
            "idUsuario": usuarioId,
            "idReporte": reporteId,
            "indBPO": bpo
        });

        if (responseObtenerReporte.status !== 200) {
            return null;
        }

        return responseObtenerReporte;
    };

    const getExtensionFromContentType = (contentType: string) => {

        const contentTypeMapping: any = {
            'application/pdf': 'pdf',
            'image/jpeg': 'jpg',
            'image/png': 'png',
            'image/gif': 'gif',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        };

        const extension = contentTypeMapping[contentType];

        if (extension) {
            return extension;
        }

        return null;

    }

    const generarDescargaContrato = async (solicitudId: number, operacionUId: number, usuarioId: number, reporteId: number, bpo: boolean, data: any) => {

        let params = { operacionUId };
        let resumenPrestamoResponse = await BTObtenerResumenPrestamo(params.operacionUId);
        if (resumenPrestamoResponse && 'code' in resumenPrestamoResponse) {
            toast.warning(`No se pudo obtener resumen de prestamo para ${data?.genericA_DESCM_CAMPO}`, { duration: appConfigKey.keyDurationToast });
        }

        let integracionSuccess = await GetMnIntegracionSolicitudInsert(solicitudId, appConfigTipoTransaccionLog.ObtenerPrestamoResumenEjecutivo, params, null, resumenPrestamoResponse);

        if (!integracionSuccess) {
            toast.error(`Falló la generación resumen informativa para ${data?.genericA_DESCM_CAMPO}`, { duration: appConfigKey.keyDurationToast });
            return;
        }

        let reporteResponse: any = await descargarReporte(solicitudId, usuarioId, reporteId, bpo);

        if (reporteResponse === null) {
            toast.error(`Falló la descarga de ${data?.genericA_DESCM_CAMPO}`, { duration: appConfigKey.keyDurationToast });
            return;
        }

        var fileName = null;
        var header = reporteResponse?.headers['content-disposition'];

        if (header) {
            var filenameRegex = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(header);
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/UTF-8['"]*/g, '');
                fileName = decodeURI(fileName);
                fileName = fileName.split('.');
                fileName = fileName[0] + '.' + getExtensionFromContentType(reporteResponse?.headers['content-type']);
            }
        }

        if (!fileName) {
            fileName = `${data?.genericA_DESCM_CAMPO}_${solicitudId}.docx`;
        }

        const url = window.URL.createObjectURL(reporteResponse?.data);
        const a = document.createElement("a");

        a.href = url;
        a.download = fileName!;
        a.click();

        window.URL.revokeObjectURL(url);
    }

    const handleAddFile = (event: any) => {
        setOpen(true);
    };

    const handleAddCheckList2File = (event: any) => {
        setOpenDialogDocumentsCheckList2(true);
    };

    const handleBackButton = (event: any) => {
        if (tabsPosition === 0) {
            navigate(`/BandejaSolicitudes/MisSolicitudes`);
        }
    };


    const ValidacionDatosAdicionales = async () => {
        let {
            oldDataTitular,
            oldDataConyuge,
            oldDataTitularEmpresa,
            oldDataTitularIngresoPrincipal,
            oldDataConyugeEmpresa,
            oldDataConyugeIngresoPrincipal
        } = await cargarDataDatosAdicionales();

        let val: any = true
        if (isDisableBtnRevision == true) {
            val = true;
        }
        if (idPerfilT == 4 || idPerfilT == 15) {
            val = true;
        }
        if (oldDataTitular) {
            // Datos del titular
            if (!oldDataTitular.iD_NACIONALIDAD) {
                val = false;
            } else if (!oldDataTitular.iD_NIVEL_ESTUDIOS) {
                val = false;
            } else if (!oldDataTitular.iD_PROFESION) {
                val = false;
            } else if (oldDataTitular.monT_NUMERO_HIJOS < 0) {
                val = false;
            } else if (oldDataTitular.monT_NUMERO_DEPENDIENTES < 0) {
                val = false;
            } else if (!oldDataTitular.descL_NOMBRE_COMPLETO_CONTACTO1?.trim()) {
                val = false;
            } else if (!oldDataTitular.descC_NUMERO_CONTACTO1?.trim()) {
                val = false;
            } else if (!oldDataTitular.descL_NOMBRE_COMPLETO_CONTACTO2?.trim()) {
                val = false;
            } else if (!oldDataTitular.descC_NUMERO_CONTACTO2?.trim()) {
                val = false;
            } else if (!oldDataTitular.descL_EMAIL?.trim()) {
                val = false;
            }

            // Datos del domicilio del titular
            if (!oldDataTitular.descM_NOMBRE_VIA?.trim()) {
                val = false;
            } else if (!oldDataTitular.descC_NUMERO?.trim()) {
                val = false;
            } else if (!oldDataTitular.iD_TIPO_VIVIENDA) {
                val = false;
            } else if (!oldDataTitular.iD_TIEMPO_RESIDENCIA) {
                val = false;
            }

            // Datos laborales del titular
            if (oldDataTitularIngresoPrincipal && oldDataTitularIngresoPrincipal.iD_CATEGORIA_LABORAL !== appConfigKey.keyIdSinCategoria) {
                if (!oldDataTitularIngresoPrincipal.iD_ACTIVIDAD_ECONOMICA) {
                    val = false;
                } else if (!oldDataTitularEmpresa.idenT_PAIS) {
                    val = false;
                } else if (!oldDataTitularEmpresa.idenT_UBIGEO || oldDataTitularEmpresa.idenT_UBIGEO.toString().length < 5) {
                    val = false;
                } else if (!oldDataTitularEmpresa.descM_NOMBRE_VIA?.trim()) {
                    val = false;
                } else if (!oldDataTitularEmpresa.descC_NUMERO?.trim()) {
                    val = false;
                } else if (!oldDataTitularEmpresa.descC_TELEFONO?.trim()) {
                    val = false;
                } else if (!oldDataTitularEmpresa.descC_CORREO?.trim()) {
                    val = false;
                } else if (!oldDataTitular.idenT_CARGO_LABORAL) {
                    val = false;
                }
            }
        }

        if (oldDataConyuge) {
            // Datos del cónyuge
            if (!oldDataConyuge.iD_NACIONALIDAD) {
                val = false;
            } else if (!oldDataConyuge.iD_NIVEL_ESTUDIOS) {
                val = false;
            } else if (!oldDataConyuge.descL_EMAIL?.trim()) {
                val = false;
            } else if (!oldDataConyuge.iD_PROFESION) {
                val = false;
            }

            // Datos laborales del cónyuge validar sin categoria
            if (oldDataConyugeIngresoPrincipal && oldDataConyugeIngresoPrincipal.iD_CATEGORIA_LABORAL !== appConfigKey.keyIdSinCategoria) {
                if (!oldDataConyugeIngresoPrincipal.iD_ACTIVIDAD_ECONOMICA) {
                    val = false;
                } else if (!oldDataConyugeEmpresa.idenT_PAIS) {
                    val = false;
                } else if (!oldDataConyugeEmpresa.idenT_UBIGEO || oldDataConyugeEmpresa.idenT_UBIGEO.toString().length < 5) {
                    val = false;
                } else if (!oldDataConyugeEmpresa.descM_NOMBRE_VIA?.trim()) {
                    val = false;
                } else if (!oldDataConyugeEmpresa.descC_NUMERO?.trim()) {
                    val = false;
                } else if (!oldDataConyugeEmpresa.descC_TELEFONO?.trim()) {
                    val = false;
                } else if (!oldDataConyugeEmpresa.descC_CORREO?.trim()) {
                    val = false;
                } else if (!oldDataConyuge.idenT_CARGO_LABORAL) {
                    val = false;
                }
            }
        }
        return val;
    }

    const [enviadoAnalista, setEnviadoAnalista] = useState(false);

    const handleEnviarAEvaluacion = async () => {

        setIsLoading(true);

        if (!solicitud) {
            toast.error(appConfigKey.keyMsjSolicitudCargando);
            setIsLoading(false);
            return;
        }

        let cantDocumento1 = primerCheckList.length;
        let cantDocumentoAdjunto1 = primerCheckList.filter((x: any) => x.fulldata.idenT_ESTADO_ADJUNTO == appConfigKey.keyIdEstadoDocumentoAdjuntado).length;
        let cantDocumentoConforme1 = primerCheckList.filter((x: any) => x.fulldata.idenT_ESTADO_ADJUNTO == appConfigKey.keyIdEstadoDocumentoConforme).length;

        if (esFlujoTercera) {
            if (cantDocumento1 == 0) {
                toast.error(appConfigKey.keyMsjAdjuntarMinDocumentos.replace('[CAMPO]', 'GESTOR'), { duration: appConfigKey.keyDurationToast });
                setIsLoading(false);
                return;
            }

            if (cantDocumento1 != (cantDocumentoAdjunto1 + cantDocumentoConforme1)) {
                toast.error(appConfigKey.keyMsjAdjuntarTodosDocumentos.replace('[CAMPO]', 'GESTOR'), { duration: appConfigKey.keyDurationToast });
                setIsLoading(false);
                return;
            }
        }

        let responseMotorBeforceStepTwo: any = null;
        if (!esFlujoTercera) {
            const tempEvaluacionSolicitud = await GetMnEvaluacionSolicitud(IdSolicitud);
            if (tempEvaluacionSolicitud.length > 0) {
                for (let evaluacion of tempEvaluacionSolicitud) {
                    if (evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepTwo) {
                        responseMotorBeforceStepTwo = evaluacion?.jsoN_RESPONSE ? JSON.parse(evaluacion.jsoN_RESPONSE) : null;
                        break;
                    }
                }
            }

            if (!responseMotorBeforceStepTwo) {
                setIsLoading(false);
                return;
            }

        }

        const listaSolicitudEtapas = await ObtenerListas.GetSolicitudEtapas(null, Number(IdSolicitud));
        let listaSolicitudEtapaRechazado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado)

        if (listaSolicitudEtapaRechazado.length > 0) {
            toast.error(appConfigKey.keyMsjEvaluacionSolicitudRechazada);
            setIsLoading(false);
            return
        }

        let listaSolicitudEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado ||
            x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion)
        if (listaSolicitudEtapaAprobado.length === listaSolicitudEtapas.length) {
            toast.error(appConfigKey.keyMsjEvaluacionSolicitudAprobada);
            setIsLoading(false);
            return
        }

        let solicitudAnteriorAprobadoConDocumentos: any = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion && x.ordenProceso === 1);
        let solicitudConAutonomia: any = listaSolicitudEtapas.filter((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarCreditoAutonomia);

        if (solicitudAnteriorAprobadoConDocumentos.length > 0 && solicitudConAutonomia.length === 0) {
            toast.error(appConfigKey.keyMsjContinuarSolicitudAprobadaConDocumentos);
            setIsLoading(false);
            return
        }
        let listaSolicitudEtapaAprobadoConDocumentos = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion)
        if (listaSolicitudEtapaAprobadoConDocumentos.length > 0) {
            toast.error(appConfigKey.keyMsjEvaluacionSolicitudAprobadaConDocumentos);
            setIsLoading(false);
            return
        }

        let listaSolicitudEnviarEvaluacionEtapaEnviado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado &&
            x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion)
        if (listaSolicitudEnviarEvaluacionEtapaEnviado.length > 0) {
            toast.error(appConfigKey.keyMsjEvaluacionSolicitudEnviado);
            setIsLoading(false);
            return
        }

        let listaSolicitudEnviarEvaluacionEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado &&
            x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion)
        if (listaSolicitudEnviarEvaluacionEtapaAprobado.length > 0) {
            toast.error(appConfigKey.keyMsjEvaluacionSolicitudAprobada);
            setIsLoading(false);
            return
        }

        let solicitudEnviarEvaluacionEtapaAprobado = listaSolicitudEtapas.find((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion)
        await General.GetSolicitudEtapasUpdate(
            {
                "identSolicitudEtapas": solicitudEnviarEvaluacionEtapaAprobado.identSolicitudEtapas,
                "identSolicitud": solicitudEnviarEvaluacionEtapaAprobado.identSolicitud,
                "identDecisionMotor": solicitudEnviarEvaluacionEtapaAprobado.identDecisionMotor,
                "identEtapa": solicitudEnviarEvaluacionEtapaAprobado.identEtapa,
                "identProceso": solicitudEnviarEvaluacionEtapaAprobado.identProceso,
                "identEstadoProceso": appConfigEstadosProcesoEtapaAnalisisCredito.Enviado,
                "indEstado": solicitudEnviarEvaluacionEtapaAprobado.indEstado,
                "identUsuarioModif": appSecurityConfiguration.SessionUserId,
                "ordenProceso": solicitudEnviarEvaluacionEtapaAprobado.ordenProceso
            }
        );

        let decision = esFlujoTercera ?
            appConfigKey.CodigoEvaluacionZonaGris :
            responseMotorBeforceStepTwo?.response?.Politica_Creditos?.decision_Credito?.decision;

        if (decision) {
            let identResultado: number = 0;
            if (decision === appConfigKey.CodigoEvaluacionAprobado) {
                identResultado = appConfigKey.keyIdResultadoAprobado
            }
            if (decision === appConfigKey.CodigoEvaluacionRechazado) {
                identResultado = appConfigKey.keyIdResultadoRechazado
            }
            if (decision === appConfigKey.CodigoEvaluacionZonaGris) {
                identResultado = appConfigKey.keyIdResultadoZonaGris
            }

            await General.GetMnResultadoEvaluacionInsert(
                {
                    "idenT_SOLICITUD": IdSolicitud,
                    "idenT_RESULTADO": identResultado,
                    "idenT_TIPO_RESULTADO": appConfigKey.KeyIdTipoResultadoSinObservaciones,
                    "idenT_DESTINO_CREDITO": solicitud.idenT_DESTINO_CREDITO,
                    "idenT_USUARIO_CREADOR": appSecurityConfiguration.SessionUserId
                }
            );
        }

        const body = {
            "idenT_SOLICITUD": IdSolicitud,
            "iD_ESTADO_SOLICITUD": appConfigKey.keyIdEstadoEnviadoBandejaAnalista,
            "idenT_USUARIO_MODIF": appSecurityConfiguration.SessionUserId,
            "descL_OBSERVACION": "",
            "movimientO_ID": 0
        };

        await RequestManager.GetSolicitudCambiarEstadoById(body).then(response => {
            if (response.status === 200) {

                const { data } = response;

                if (!data.isValid) {
                    toast.error("Ocurrió un error con el servicio para actualizar el estado de la solicitud.");
                }

                toast.success("Se envío solicitud a mesa de créditos.");
                setEnviadoAnalista(true);
                obtenerConfigAccionesPorSolicitud();


            }
        }).catch(error => {
            toast.error("Ocurrió un error con el servicio para actualizar el estado de la solicitud.");
        });
        setIsLoading(false);

    };

    const handleSendValidation = async (event: any) => { 
        if (event?.preventDefault) {
            event.preventDefault();
        }

        try {
            setIsLoading(true);
            await handleCoreSendValidation(event);
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    }

    const handleCoreSendValidation = async (event: any) => {
        if (!solicitud) {
            toast.error(appConfigKey.keyMsjSolicitudCargando);
            return;
        }

        let mensajeOk = '';
        let responseMotorBeforceStepTwo: any = null;
        let idUsuarioLogeador = Number(
            localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
        )

        if (appSecurityConfiguration.SessionPerfil !== appConfigPerfiles.CodigoEjecutivo &&
            appSecurityConfiguration.SessionPerfil !== appConfigPerfiles.CodigoJefeComercial
        ) {
            toast.error(appConfigKey.keyMsjEjecutivoDocumentos, { duration: appConfigKey.keyDurationToast });
            return;
        }

        if (solicitud.idenT_USUARIO_CREADOR !== idUsuarioLogeador) {
            toast.error(appConfigKey.keyMsjEjecutivoDocumentosPropios, { duration: appConfigKey.keyDurationToast });
            return;
        }

        if (solicitud.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoSolicitudPorValidar) {
            setIsDisabledFileUploader1(true);
            toast.error(appConfigKey.keyMsjDocumentosEnviados.replace('[CAMPO]', 'GESTOR'), { duration: appConfigKey.keyDurationToast });
            return;
        }

        if (solicitud.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoSolicitudPorRevisar) {
            setIsDisabledFileUploader2(true);
            toast.error(appConfigKey.keyMsjDocumentosEnviados.replace('[CAMPO]', 'BPO'), { duration: appConfigKey.keyDurationToast });
            return;
        }



        const requestBody: any = JsonHelper.crearJsonGetSolicitudCambiarEstadoById();
        requestBody.idenT_SOLICITUD = IdSolicitud;
        requestBody.idenT_USUARIO_MODIF = appSecurityConfiguration.SessionUserId;

        if (checklist === 0) {
            let cantDocumento1 = primerCheckList.length;
            let cantDocumentoAdjunto1 = primerCheckList.filter((x: any) => x.fulldata.idenT_ESTADO_ADJUNTO == appConfigKey.keyIdEstadoDocumentoAdjuntado).length;
            let cantDocumentoConforme1 = primerCheckList.filter((x: any) => x.fulldata.idenT_ESTADO_ADJUNTO == appConfigKey.keyIdEstadoDocumentoConforme).length;

            if (cantDocumento1 === cantDocumentoConforme1) {
                toast.error(appConfigKey.keyMsjConformeTodosDocumentos, { duration: appConfigKey.keyDurationToast });
                return;
            }

            if (cantDocumento1 !== (cantDocumentoAdjunto1 + cantDocumentoConforme1)) {
                toast.error(appConfigKey.keyMsjAdjuntarTodosDocumentos.replace('[CAMPO]', 'GESTOR'), { duration: appConfigKey.keyDurationToast });
                return;
            }

            if (!esFlujoTercera) {
                const tempEvaluacionSolicitud = await GetMnEvaluacionSolicitud(IdSolicitud);
                if (tempEvaluacionSolicitud.length > 0) {
                    for (let evaluacion of tempEvaluacionSolicitud) {
                        if (evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepTwo) {
                            responseMotorBeforceStepTwo = evaluacion?.jsoN_RESPONSE ? JSON.parse(evaluacion.jsoN_RESPONSE) : null;
                            break;
                        }
                    }
                }

                if (!responseMotorBeforceStepTwo) { return; }
            }

            const listaSolicitudEtapas = await ObtenerListas.GetSolicitudEtapas(null, Number(IdSolicitud));
            let listaSolicitudEtapaRechazado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado)

            if (listaSolicitudEtapaRechazado.length > 0) {
                toast.error(appConfigKey.keyMsjValidacionSolicitudRechazada);
                return
            }

            let listaSolicitudEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado)
            if (listaSolicitudEtapaAprobado.length === listaSolicitudEtapas.length) {
                toast.error(appConfigKey.keyMsjValidacionSolicitudAprobada);
                return
            }


            let listaSolicitudEnviarValidacionEtapaEnviado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado &&
                x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarValidacion)
            if (listaSolicitudEnviarValidacionEtapaEnviado.length > 0) {
                toast.error(appConfigKey.keyMsjValidacionSolicitudEnviado);
                return
            }

            let listaSolicitudEnviarValidacionEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado &&
                x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarValidacion)
            if (listaSolicitudEnviarValidacionEtapaAprobado.length > 0) {
                toast.error(appConfigKey.keyMsjValidacionSolicitudAprobada);
                return
            }

            let solicitudEnviarValidacionEtapaAprobado = listaSolicitudEtapas.find((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarValidacion)
            await General.GetSolicitudEtapasUpdate(
                {
                    "identSolicitudEtapas": solicitudEnviarValidacionEtapaAprobado.identSolicitudEtapas,
                    "identSolicitud": solicitudEnviarValidacionEtapaAprobado.identSolicitud,
                    "identDecisionMotor": solicitudEnviarValidacionEtapaAprobado.identDecisionMotor,
                    "identEtapa": solicitudEnviarValidacionEtapaAprobado.identEtapa,
                    "identProceso": solicitudEnviarValidacionEtapaAprobado.identProceso,
                    "identEstadoProceso": appConfigEstadosProcesoEtapaAnalisisCredito.Enviado,
                    "indEstado": solicitudEnviarValidacionEtapaAprobado.indEstado,
                    "identUsuarioModif": appSecurityConfiguration.SessionUserId,
                    "ordenProceso": solicitudEnviarValidacionEtapaAprobado.ordenProceso
                }
            );


            mensajeOk = appConfigKey.keyMsjEnviandoBandeja.replace('[CAMPO]', 'GESTOR');
            requestBody.iD_ESTADO_SOLICITUD = appConfigKey.keyIdEstadoEnviadoBandejaGestor;

        }
        if (checklist === 1) {
            let cantDocumento2 = segundoCheckList.length;
            let cantDocumentoAdjunto2 = segundoCheckList.filter((x: any) => x.fulldata.idenT_ESTADO_ADJUNTO == appConfigKey.keyIdEstadoDocumentoAdjuntado).length;
            let cantDocumentoConforme2 = segundoCheckList.filter((x: any) => x.fulldata.idenT_ESTADO_ADJUNTO == appConfigKey.keyIdEstadoDocumentoConforme).length;
            let cantDocumentoObservado2 = segundoCheckList.filter((x: any) => x.fulldata.idenT_ESTADO_ADJUNTO == appConfigKey.keyIdEstadoDocumentoObservado).length;

            if (cantDocumento2 === cantDocumentoConforme2) {
                toast.error(appConfigKey.keyMsjConformeTodosDocumentos, { duration: appConfigKey.keyDurationToast });
                return;
            }

            if (cantDocumentoAdjunto2 === 0 || cantDocumentoObservado2 > 0) {
                toast.error(appConfigKey.keyMsjAdjuntarTodosDocumentos.replace('[CAMPO]', 'BPO'), { duration: appConfigKey.keyDurationToast });
                return;
            }

            let validacion: boolean = false;
            if (esJuridico) {
                validacion = true;
            } else {
                try {
                    validacion = await ValidacionDatosAdicionales();
                } catch {
                    toast.error(appConfigKey.keyMsjDatosAdicionalesValidacionError, { duration: appConfigKey.keyDurationToast });
                    return;
                }
            }

            if ((idPerfilT != 4 && idPerfilT != 15) && validacion == false) {
                toast.error("Datos Adicionales son obligatorios", { duration: appConfigKey.keyDurationToast });
                return;
            }

            mensajeOk = appConfigKey.keyMsjEnviandoBandeja.replace('[CAMPO]', 'BPO');
            requestBody.iD_ESTADO_SOLICITUD = appConfigKey.keyIdEstadoEnviadoBandejaBPO;
        }

        if (requestBody.iD_ESTADO_SOLICITUD == appConfigKey.keyIdEstadoEnviadoBandejaGestor) {


            let decision = esFlujoTercera ? appConfigKey.CodigoEvaluacionZonaGris : responseMotorBeforceStepTwo?.response?.Politica_Creditos?.decision_Credito?.decision;
            if (decision) {

                let identResultado: number = 0;
                if (decision === appConfigKey.CodigoEvaluacionAprobado) {
                    identResultado = appConfigKey.keyIdResultadoAprobado
                }
                if (decision === appConfigKey.CodigoEvaluacionRechazado) {
                    identResultado = appConfigKey.keyIdResultadoRechazado
                }
                if (decision === appConfigKey.CodigoEvaluacionZonaGris) {
                    identResultado = appConfigKey.keyIdResultadoZonaGris
                }

                await General.GetMnResultadoEvaluacionInsert(
                    {
                        "idenT_SOLICITUD": IdSolicitud,
                        "idenT_RESULTADO": identResultado,
                        "idenT_TIPO_RESULTADO": appConfigKey.KeyIdTipoResultadoSinObservaciones,
                        "idenT_DESTINO_CREDITO": solicitud.idenT_DESTINO_CREDITO,
                        "idenT_USUARIO_CREADOR": appSecurityConfiguration.SessionUserId
                    }
                );
            }
        }

        const response = await RequestManager.GetSolicitudCambiarEstadoById(requestBody);
        if (response) {
            let resultado = response.data.isValid;
            if (resultado === true) {
                toast.success(mensajeOk, { duration: appConfigKey.keyDurationToast });
                obtenerDocumentos();
                obtenerPerfilTracking();
                obtenerConfigAccionesPorSolicitud();
            }
        }

    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleDialogCheckList2Close = () => {
        setOpenDialogDocumentsCheckList2(false);
    };

    const handleDialogDNIOpen = () => {
        setOpenDIalogDNI(true);
    };

    const handleDialogDNIClose = () => {
        setOpenDIalogDNI(false);
    };

    const handleChangeSwitch = async (selecteds: any) => {

        for (var i = 0; i < selecteds.length; i++) {

            const body = {
                "idenT_DOCUMENTACION_DOCUMENTO": 0,
                "idenT_SOLICITUD": IdSolicitud,
                "idenT_TIPO_LISTADO": 2,
                "descC_TIPO_LISTADO": "CHECKLIST2",
                "idenT_TIPO_DOCUMENTO": selecteds[i].codigo,
                "descC_TIPO_DOCUMENTO": selecteds[i].descripcion,
                "idenT_ESTADO_ADJUNTO": appConfigKey.keyIdEstadoDocumentoPorAdjuntar,
                "descC_ESTADO_ADJUNTO": appConfigKey.keyDescripEstadoDocumentoPorAdjuntar,
                "descC_NOMBRE_ARCHIVO": null,
                "descC_EXTENSION_ARCHIVO": null,
                "idenT_DOCUMENTO_BT": 0,
                "idenT_USUARIO": appSecurityConfiguration.SessionUserId
            };

            await MasterManager.GetMnDocumentacionDocumentoInsert(body);

            handleDialogCheckList2Close();
            obtenerDocumentos();
        }

    };

    const handleChangeSwitchListUno = async (selecteds: any) => {

        for (var i = 0; i < selecteds.length; i++) {

            const body = {
                "idenT_DOCUMENTACION_DOCUMENTO": 0,
                "idenT_SOLICITUD": IdSolicitud,
                "idenT_TIPO_LISTADO": 1,
                "descC_TIPO_LISTADO": "CHECKLIST1",
                "idenT_TIPO_DOCUMENTO": selecteds[i].codigo,
                "descC_TIPO_DOCUMENTO": selecteds[i].descripcion,
                "idenT_ESTADO_ADJUNTO": appConfigKey.keyIdEstadoDocumentoPorAdjuntar,
                "descC_ESTADO_ADJUNTO": appConfigKey.keyDescripEstadoDocumentoPorAdjuntar,
                "descC_NOMBRE_ARCHIVO": null,
                "descC_EXTENSION_ARCHIVO": null,
                "idenT_DOCUMENTO_BT": 0,
                "idenT_USUARIO": appSecurityConfiguration.SessionUserId
            };

            await MasterManager.GetMnDocumentacionDocumentoInsert(body);
        }
        handleDialogClose();
        obtenerDocumentos();
    };

    const handleDownloadAllContratoFile = async (event: any) => {
        event.preventDefault();
        try {
            setIsLoading(true)
            for (let index = 0; index < contratoList.length; index++) {
                const data = contratoList[index];
                await generarDescargaContrato(solicitud?.idenT_SOLICITUD, solicitud?.idenT_OPERACION, appSecurityConfiguration.SessionUserId, data.genericA_IDENT_CAMPO, false, data);

            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleDownloadContratoFile = async (event: any, data: any) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            await generarDescargaContrato(solicitud?.idenT_SOLICITUD, solicitud?.idenT_OPERACION, appSecurityConfiguration.SessionUserId, data.genericA_IDENT_CAMPO, false, data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }
    const handleCloseRequestExceptionDialog = () => {
        setOpenRequestExceptionDialog(false);
    };
    const handleSolicitarExcepcion = () => {
        setOpenRequestExceptionDialog(true);
    }

    const GetMnEvaluacionSolicitud = async (identSolicitud: any) => {
        let evaluaciones = [];
        try {
            let response = await MasterManager.GetMnEvaluacionSolicitud(identSolicitud);
            if (response && response.status === 200) {
                evaluaciones = response.data.content;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA EVALUACION DE LA SOLICITUD.');
        }
        return evaluaciones;
    }

    return (
        <div>
            {isLoading && <LoadingProgress />}
            <Toaster position="top-center" toastOptions={{ style: { width: '500px', margin: '0 auto' } }} richColors closeButton />

            <MainLayout />
            <DocumentationCardTitle identification={IdSolicitud} />
            <div className="row g-2 mb-2 cardDocumentation">
                <div className="col-lg-12">
                    <DocumentationAlert handleOnClick={handleDocumentationAlert} show={showAlert} />
                </div>
            </div>
            <div className="row g-2 mb-2 cardDocumentation">
                <div className="col-lg-9">
                    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
                        <div className="row g-2 mb-2 align-items-center">
                            <div className="rounded-10 p-3 p-md-4 mb-4 mb-md-4">
                                <Tabs value={tabsPosition} onChange={handleTabsPosition}>
                                    {headerTitles.map((header, index) => (<Tab key={index} label={header} className="header-tab-documentation" />))}
                                </Tabs>
                                <DocumentationTabPanel value={tabsPosition} index={0}>

                                    {/*<div style={{ display: checklist === 0 ? 'inherit' : 'none' }}>
                                        <DocumentationTable
                                            widthColumns={widthColumns}
                                            tableHeaders={tableHeaders}
                                            tableData={primerCheckList}
                                            addStyleStatus={addStyleStatus}
                                            renderStatus={renderStatus}
                                            addStyleFile={addStyleFile}
                                            handlerFile={handleFile}
                                            handleDialogDNIOpen={handleDialogDNIOpen}
                                            handleDownloadFile={handleDownloadFile}
                                        />
                                        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px' }}>
                                            <DocumentationAddFile handlerOnClick={handleAddFile} />
                                        </div>
                                    </div>*/}

                                    {/* <div style={{ display: checklist === 0 ? 'inherit' : 'none' }}>*/}
                                    <div style={{ display: 'inherit' }}>
                                        <DocumentationCheckList2Table
                                            widthColumns={widthColumns}
                                            tableHeaders={tableChecklist2Headers}
                                            tableData={segundoCheckList}
                                            addStyleStatus={addStyleStatus}
                                            renderStatus={renderStatus}
                                            addStyleFile={addStyleFile}
                                            handlerFile={handleFile}
                                            handleDownloadFile={handleDownloadFile}
                                            isDisabledFileUploader={isDisabledFileUploader2}
                                        />
                                        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginBottom: '20px' }}>
                                            {solicitud?.iD_ESTADO_SOLICITUD !== appConfigKey.keyIdEstadoSolicitudActivo &&
                                                solicitud?.iD_ESTADO_SOLICITUD !== appConfigEstadoSolicitud.Rechazado &&
                                                (<DocumentationAddFile handlerOnClick={handleAddCheckList2File} />)
                                            }
                                        </div>
                                        <DocumentationTable
                                            widthColumns={widthColumns}
                                            tableHeaders={tableHeaders}
                                            tableData={primerCheckList}
                                            addStyleStatus={addStyleStatus}
                                            renderStatus={renderStatus}
                                            addStyleFile={addStyleFile}
                                            handlerFile={handleFile}
                                            handleDialogDNIOpen={handleDialogDNIOpen}
                                            handleDownloadFile={handleDownloadFile}
                                            isDisabledFileUploader={isDisabledFileUploader1}
                                            disabledUploadFiles={
                                                (isDisableBtnEnviarEvaluacion ||
                                                    isDisableBtnSolicitarExcepcion ||
                                                    isDisableBtnEnviarValidacion) ||
                                                (!isVisibleBtnEnviarEvaluacion &&
                                                    !isVisibleBtnSolicitarExcepcion &&
                                                    !isVisibleBtnEnviarValidacion)
                                            }

                                        />
                                        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginBottom: '20px' }}>
                                            {solicitud?.iD_ESTADO_SOLICITUD !== appConfigKey.keyIdEstadoSolicitudActivo &&
                                                solicitud?.iD_ESTADO_SOLICITUD !== appConfigEstadoSolicitud.Rechazado &&
                                                solicitud?.iD_ESTADO_SOLICITUD !== appConfigEstadoSolicitud.PorValidar &&
                                                (<DocumentationAddFile
                                                    disabled={
                                                        (isDisableBtnEnviarEvaluacion ||
                                                            isDisableBtnSolicitarExcepcion ||
                                                            isDisableBtnEnviarValidacion) ||
                                                        (!isVisibleBtnEnviarEvaluacion &&
                                                            !isVisibleBtnSolicitarExcepcion &&
                                                            !isVisibleBtnEnviarValidacion)
                                                    }
                                                    handlerOnClick={handleAddFile}
                                                />)
                                            }
                                        </div>
                                    </div>
                                </DocumentationTabPanel>
                                {/*
                                    <DocumentationTabPanel value={TabsPosition} index={1}>
                                        <div className='filter-verification-table'>
                                            <div>
                                                <span>Tipo de verificación</span>
                                                <Select
                                                    placeholder="Seleccione"
                                                    indicator={<KeyboardArrowDown />}
                                                    sx={{
                                                        width: 240,
                                                        [`& .${selectClasses.indicator}`]: {
                                                            transition: '0.2s',
                                                            [`&.${selectClasses.expanded}`]: {
                                                                transform: 'rotate(-180deg)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Option value="C01">Verificación domiciliaria</Option>
                                                    <Option value="C02">Verificación laboral</Option>
                                                    <Option value="C03">Verificación telefónica</Option>
                                                </Select>
                                            </div>
                                            <div className='btn-filter-verification-table cursor-pointer'>Solicitar</div>
                                        </div>
                                        <DocumentationVerificationTable
                                            widthColumns={widthColumns}
                                            tableHeaders={tableVerificationHeaders}
                                            tableData={tableVerificationData}
                                            handlerFile={handleFile}
                                        />
                                    </DocumentationTabPanel>
                                */}
                                <DocumentationTabPanel value={tabsPosition} index={1}>
                                    <DocumentationContratosTable
                                        widthColumns={contratosWidthColumns}
                                        tableHeaders={tableContratosHeaders}
                                        tableData={contratoList}
                                        handleClick={handleDownloadContratoFile}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                                        <DocumentationDownload text='Descargar todo' handlerOnClick={handleDownloadAllContratoFile} />
                                    </div>
                                </DocumentationTabPanel>
                                {/*<DocumentationTabPanel value={TabsPosition} index={2}>
                                    <div className='filter-placa-table'>
                                        <div>
                                            <span>Nro. de placa</span>
                                            <Input style={{ width: '240px' }} onInput={(value: any) => { setPlacaNumber(value.target.value) }} />
                                        </div>
                                        <div className='btn-filter-verification-table cursor-pointer' style={{ marginRight: '30px' }} onClick={handlePlacaSearch}>Validar</div>
                                        <DocumentationPlacaValidation show={showPlacaValidation} />
                                    </div>
                                    <DocumentationVerificationTable
                                        widthColumns={widthColumns}
                                        tableHeaders={tableVerificationHeaders}
                                        tableData={tableVerificationData}
                                        handlerFile={handleFile}
                                    />
                                </DocumentationTabPanel>
                            */}
                            </div>
                        </div>
                    </div>
                    <div className='documentation-button-steps'>
                        <DocumentationBtnBack handlerOnClick={handleBackButton} />
                        {!esJuridico && (
                            <button
                                className='solicitude-button-documentation'
                                onClick={handleShowOpenDatosAdicionales}
                                disabled={((idPerfilT == appConfigPerfiles.CodigoEjecutivo ||
                                    idPerfilT == appConfigPerfiles.CodigoJefeComercial ||
                                    idPerfilT == appConfigPerfiles.CodigoGestor) && (
                                        solicitud?.iD_ESTADO_SOLICITUD == 48 || //APROBADO
                                        solicitud?.iD_ESTADO_SOLICITUD == 49 || //RECHAZADO
                                        solicitud?.iD_ESTADO_SOLICITUD == 87 || //REGISTRO DE SOLICITUD
                                        solicitud?.iD_ESTADO_SOLICITUD == 88 || //OBSERVADO
                                        solicitud?.iD_ESTADO_SOLICITUD == 94 || //ENVIANDO A BANDEJA VALIDACION DOCUMENTAL
                                        solicitud?.iD_ESTADO_SOLICITUD == 95 || //POR VALIDAR
                                        solicitud?.iD_ESTADO_SOLICITUD == 96 || //EN VALIDACION
                                        solicitud?.iD_ESTADO_SOLICITUD == 2342 || //POR EVALUAR
                                        solicitud?.iD_ESTADO_SOLICITUD == 2346 ||
                                        solicitud?.iD_ESTADO_SOLICITUD == 2347 ||
                                        solicitud?.iD_ESTADO_SOLICITUD == 2343 ||
                                        solicitud?.iD_ESTADO_SOLICITUD == 2349 ||
                                        solicitud?.iD_ESTADO_SOLICITUD == 2350 ||
                                        solicitud?.iD_ESTADO_SOLICITUD == 2344
                                    )) ? false : true}
                            >Datos Adicionales</button>
                        )}

                        {solicitud &&
                            solicitud.iD_ESTADO_SOLICITUD !== appConfigEstadoSolicitud.Rechazado &&
                            (
                                <div className='documentation-button-actions-section'>

                                    {tabsPosition === 0 &&
                                        isVisibleBtnEnviarEvaluacion &&
                                        (
                                            <DocumentationSendValidation
                                                disabled={isDisableBtnEnviarEvaluacion}
                                                text={'Enviar a Evaluación'}
                                                handlerOnClick={handleEnviarAEvaluacion}
                                            />
                                        )}

                                    {
                                        isVisibleBtnSolicitarExcepcion
                                        &&
                                        <DocumentationSendValidation disabled={isDisableBtnSolicitarExcepcion} text={'Solicitar Excepción'} handlerOnClick={handleSolicitarExcepcion} />
                                    }

                                    {tabsPosition === 0 && checklist === 0 && isVisibleBtnEnviarValidacion &&
                                        (
                                            <DocumentationSendValidation disabled={isDisableBtnEnviarValidacion} text={'Enviar a validación'} handlerOnClick={handleSendValidation} />
                                        )
                                    }

                                    {tabsPosition === 0 && checklist === 1 &&
                                        !isVisibleBtnSolicitarExcepcion &&
                                        !isVisibleBtnEnviarEvaluacion &&
                                        !isVisibleBtnEnviarValidacion
                                        &&
                                        solicitud?.iD_ESTADO_SOLICITUD !== appConfigKey.keyIdEstadoSolicitudActivo &&
                                        solicitud?.iD_ESTADO_SOLICITUD !== appConfigKey.keyIdEstadoPendiente &&
                                        solicitud?.iD_ESTADO_SOLICITUD !== appConfigKey.keyIdEstadoPorEvaluar &&
                                        solicitud?.iD_ESTADO_SOLICITUD !== appConfigKey.keyIdEstadoEnviadoBandejaAnalista &&
                                        solicitud?.iD_ESTADO_SOLICITUD !== appConfigEstadoSolicitud.Rechazado &&
                                        (
                                            <DocumentationSendValidation text={'Enviar a revisión'} handlerOnClick={handleSendValidation} />
                                        )
                                    }

                                </div>
                            )}
                    </div>
                </div>
                {(codigoDecisionMotor !== appConfigKey.CodigoEvaluacionRechazado
                ) && (<div className="col-lg-3">
                    <div style={{ width: '91%' }}>
                        {tabsPosition === 0 && (
                            <div style={{ marginBottom: '20px' }}>
                                <DocumentationNote>
                                    <p><strong>Solicitud aprobada</strong>, solo falta subir los documentos requeridos y agrega nuevos si fuera necesario y envíalos a revisión.</p>
                                </DocumentationNote>
                            </div>
                        )}
                        {/*<DocumentationChat />*/}
                    </div>
                </div>)}
                {(codigoDecisionMotor === appConfigKey.CodigoEvaluacionRechazado &&
                    (solicitud?.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoPendiente ||
                        solicitud?.iD_ESTADO_SOLICITUD === appConfigKey.KeyIdEstadoSolicitudObservado ||
                        solicitud?.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoSolicitudPorLiberarExcepcion ||
                        solicitud?.iD_ESTADO_SOLICITUD === appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion
                    )) && (<div className="col-lg-3">
                        <div style={{ width: '91%' }}>
                            <div style={{ marginBottom: '20px', color: 'red' }}>
                                <DocumentationRechazado>
                                    {mensajeDecisionMotor}
                                </DocumentationRechazado>
                            </div>
                        </div>
                    </div>)}
            </div>
            <DocumentationDialogAdd
                open={open}
                handleClose={handleDialogClose}
                tableData={totalDocumentosListUno}
                handleChangeSwitch={handleChangeSwitchListUno}
                handleOpenSecondModal={handleDialogAddDescriptionOpen}
                handleTipoChk={handleTipoCheckList1}
            />
            <DocumentationDialogAdd
                open={openDialogDocumentsCheckList2}
                handleClose={handleDialogCheckList2Close}
                tableData={totalDocumentos}
                handleChangeSwitch={handleChangeSwitch}
                handleOpenSecondModal={handleDialogAddDescriptionOpen}
                handleTipoChk={handleTipoCheckList2}
            />
            <DocumentationDialogDNI
                open={openDialogDNI}
                handleClose={handleDialogDNIClose}
                handleFile={handleFile}
            />
            <DatosAdicionalesModal
                data={dataAdicionalSolicitud}
                open={openDatosAdicionales}
                handleClose={handleCloseOpenDatosAdicionales}
                solicitudData={solicitud}
                setBtnModal={setBtnModal}
                cargarDataDatosAdicionales={cargarDataDatosAdicionales}
            />
            <RequestExceptionDialog
                data={{ IdSolicitud: IdSolicitud }}
                solicitud={solicitud}
                open={openRequestExceptionDialog}
                handleClose={handleCloseRequestExceptionDialog}
                handleObtenerDocumentos={obtenerDocumentos}
                handleObtenerConfigAccionesPorSolicitud={obtenerConfigAccionesPorSolicitud}
            />

            <DocumentationAddDescription
                open={openDialogAddDescription}
                handleClose={handleDialogAddDescriptionClose}
                handleAgregar={handleAgregarTxt}
            />
        </div>
    );
}